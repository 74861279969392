import React from "react";
import {
  formatDate,
  formatTime,
  slashedDate,
  uppercaseString,
} from "../helpers/helpers";

export default function TransactionHistoryCard({ data }) {
  const dollarSign = String.fromCodePoint(0x00024);

  return (
    <div className="TransactionHistoryCard Card shadow-none md:flex md:h-[286px] justify-between items-center px-6 py-8 bg-bafWhite md:px-16">
      <div className="flex flex-col gap-6 mb-6 md:mb-0 md:gap-0 md:justify-between md:h-full">
        <div className="AmountWrapper">
          <p className="text-sm font-semibold mb-4">Amount</p>
          <p className="text-[40px] leading-10 font-semibold">{`${dollarSign}
          ${
            data.totalAmountPaid
              ? data.totalAmountPaid.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                })
              : "0.00"
          } USD`}</p>
        </div>
        <div className="PayeeWrapper mt-4">
          <p className="text-sm font-semibold">Payee</p>
          <p
            href="#"
            className="text-sm max-w-fit shadow-[inset_0_-0.6em_#A590E3]"
          >
            {data?.payee}
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-6 md:w-[50%] md:h-full md:justify-between">
        <div className="flex justify-between mt-4 md:mt-0">
          <div>
            <p className="text-sm font-semibold">Transaction Status</p>
            <p className="text-sm">{uppercaseString(data?.status)}</p>
          </div>

          <div>
            <p className="text-sm font-semibold">Transaction Time</p>
            <p className="text-sm">
              {`${slashedDate(data?.createTs)} ${formatTime(data?.createTs)}`}
            </p>
          </div>
        </div>

        <div className="">
          <p className="text-sm font-semibold">Transaction ID</p>
          <p className="text-sm">{data?.transactionId}</p>
        </div>

        <div className="">
          <p className="text-sm font-semibold">Note:</p>
          <p className="text-sm">
            Payment for generating lead(s) for the “{data?.campaignName}”
            campaign from {data?.campaignStart} to {data?.campaignEnd}.
          </p>
        </div>
      </div>
    </div>
  );
}
