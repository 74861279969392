import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";

export default function useDropDownValues() {
  const [dropDownValues, setDropDownValues] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_PROD_URL}registrationDropdownValues`)
      .then((res) => setDropDownValues(res.data.response));
  }, []);

  return dropDownValues;
}
