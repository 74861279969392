import React from "react";

import { abbreviateNumber, formatDate } from "../helpers/helpers";
import { Link, useNavigate } from "react-router-dom";
import MiniAvatar from "../Shared/MiniAvatar";

const ProgressBar = ({ progressPercentage, status }) => {
  return (
    <div className="h-2 w-[35%] rounded-md border border-[#d7d7d8]">
      <div
        style={{ width: `${progressPercentage}%` }}
        className={`h-full ${
          status === "active" ? "bg-darkGreen" : "bg-darkPurple"
        }`}
      ></div>
    </div>
  );
};

export default function CampaignCard({
  campaign,
  noEdit,
  wrapperClass,
  noClick,
}) {
  const {
    companyName,
    leadPayout,
    leadPayoutPerCoversion,
    leadGoal,
    status,
    campaignName,
    campaignImageLink,
    campaignDescription,
    campaignThumbNailLink,
    totalLeadsGenerated,
    campaignStartDate,
    campaignEndDate,
    totalShares,
    totalLeads,
    campaignId,
    affiliateImgList,
  } = campaign || {};

  const startDate = formatDate(campaignStartDate, true);
  const endDate = formatDate(campaignEndDate, true);
  const leadProgress = (totalLeadsGenerated / leadGoal) * 100;
  let navigate = useNavigate();

  const campaignRedirect = () => {
    if (noClick) {
      return;
    }
    navigate(`/single-campaign/${campaignId}`);
  };

  const getAffiliateAvatarPosition = (index) => {
    const avatarPosition = {
      0: "right-[20px]",
      1: "right-[35px]",
      2: "right-[50px]",
    };

    return avatarPosition[index];
  };

  return (
    <div
      className={`CampaignCard mb-2 h-[400px] w-[300px] cursor-pointer rounded-[0.625rem] bg-bafWhite shadow-[0px_4px_4px_rgba(0,0,0,0.1),5px_-1px_10px_#F3F3F3] ${
        wrapperClass || null
      }`}
      onClick={() => campaignRedirect()}
    >
      <header className={`h-32 bg-cover bg-center bg-no-repeat`}>
        <img
          src={campaignImageLink}
          className="h-full w-full rounded-t-md object-cover"
          alt="campaign header"
        />
        {!noEdit && (
          <Link
            className="relative left-[64%] top-[-120px] rounded-xl border border-black bg-bafWhite px-7 py-[3px] text-[10px] font-bold hover:bg-black hover:text-white"
            to={`/edit-campaign/${campaignId}`}
            onClick={(e) => e.stopPropagation()}
          >
            Edit
          </Link>
        )}
      </header>
      <div
        onClick={() => campaignRedirect()}
        className={`StatusBarWrapper relative top-[-12px]  z-0  flex items-center justify-around before:absolute before:h-[3px] before:w-full before:content-[''] ${
          status === "active" ? "before:bg-darkGreen" : "before:bg-darkPurple"
        }`}
      >
        <div
          className={`Status z-[1] -ml-[5px] max-w-fit rounded-[5px] px-[7px] py-[3px]
          ${
            status === "active"
              ? "bg-[#C6F3EB] px-2 text-bafWhite"
              : "border border-darkPurple bg-bafWhite"
          }`}
        >
          <p
            className={`text-[10px] uppercase
            ${status === "active" ? " text-darkGreen" : "text-darkPurple"}`}
          >
            {status}
          </p>
        </div>
        <div className="Affiliates relative z-[1] mb-2 ml-[100px] flex max-w-fit">
          {affiliateImgList &&
            affiliateImgList.map((img, index) => (
              <MiniAvatar
                src={img}
                className={`absolute bg-white max-w-[25px] ${getAffiliateAvatarPosition(
                  index
                )} `}
              />
            ))}
          <span
            className={`flex items-center justify-center rounded-full border border-white w-[25px] text-[10px] ${
              status === "active"
                ? "bg-[#C6F3EB] text-darkGreen"
                : "bg-lightPurple text-darkPurple"
            }`}
          >
            {totalShares}
          </span>
        </div>
      </div>
      <div
        onClick={() => campaignRedirect()}
        className="px-6 hover:cursor-pointer"
      >
        <h4 className="truncate text-2xl font-semibold ">{campaignName}</h4>
        <p className="text-[10px] text-bafGrey">
          By {companyName} -{" "}
          <span
            className={`font-bold ${
              status === "active" ? "text-darkGreen" : "text-darkPurple"
            }`}
          >
            {`${startDate} ${startDate === endDate ? "" : `- ${endDate}`}`}
          </span>
        </p>

        <p className="min-h-[65px] pt-4 text-xs line-clamp-3">
          {`"${campaignDescription}"`}
        </p>

        <div className="mt-4 flex justify-between">
          <div className="flex flex-col items-center">
            <h5 className="text-xl font-bold">
              {abbreviateNumber(totalShares) || 0}
            </h5>
            <p className="text-xs text-darkGray">Shares</p>
          </div>

          <div className="flex flex-col items-center">
            <h5 className="text-xl font-bold">
              {`$ ${leadPayout || leadPayoutPerCoversion || 0}`}
            </h5>
            <p className="text-xs text-darkGray">Per Lead</p>
          </div>

          <div className="flex flex-col items-center">
            <h5 className="text-xl font-bold">
              {abbreviateNumber(totalLeadsGenerated || 0)}
            </h5>
            <p className="text-xs text-darkGray">Leads</p>
          </div>
        </div>
      </div>
      <footer className="mt-4 flex items-center justify-between px-6">
        <p className="text-xs text-darkGray">Lead Progress:</p>
        <ProgressBar
          className="w-[40%]"
          progressPercentage={
            leadProgress > 100
              ? 100
              : isNaN(leadProgress) === true
              ? 0
              : leadProgress
          }
          status={status}
        />
        <p className="text-xs">of {leadGoal || 0}</p>
      </footer>
    </div>
  );
}
