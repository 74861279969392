import React from "react";
import { Link } from "react-router-dom";
import AccountStatement from "./AccountStatement";

export default function AccountStatements({ statements }) {
  const shortenedNotifications =
    statements && Array.isArray(statements) && statements.slice(0, 3);

  return (
    <div className="AccountStatements Card bg-bafWhite w-full h-[363px] p-6 md:px-4 lg:px-6">
      <header className="flex justify-between items-center">
        <h2 className="text-sm phone:text-[1rem] md:text-[20px]">
          Account Statements
        </h2>
        <Link to="/wallet" className="text-sm font-bold">
          View All
        </Link>
      </header>
      <div className="flex flex-col mt-4">
        {shortenedNotifications &&
          shortenedNotifications.map((statement) => {
            return (
              <AccountStatement
                statement={statement}
                key={statement.campaignId}
              />
            );
          })}
      </div>
    </div>
  );
}
