import axios from "axios";
import { useFormik } from "formik";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Container from "../Shared/Container";
import HollowButton from "../Shared/HollowButton";
import SignUpInput from "../Shared/SignUpInput";
import spaceship from "../images/starships.gif";
import AuthBackground from "../Shared/AuthBackground";

export default function ForgotEmail() {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [page, setPage] = useState(1);
  const [email, setEmail] = useState("");

  useEffect(() => {
    document.title = "Brandaf - Brand Login";
  }, []);
  const login = `${process.env.REACT_APP_PROD_URL}forgotPasswordNew`;

  const formik = useFormik({
    initialValues: {
      emailId: "",
    },
    onSubmit: (values, { resetForm }) => {
      axios
        .post(login, values, {
          headers: { "Access-Control-Allow-Origin": "*" },
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.responseCode === 0) {
            setSuccess(res.data.successMsg);
            setEmail(res.data.response.emailId);
            setPage((page) => page + 1);
          } else {
            setError(res.data.errorMsg);
          }
        });
    },
  });

  //* Disable Values
  const disableNextButton = !formik.values.emailId;

  // const nextPage = () => {
  //   setPage((prevPage) => (prevPage += 1));
  //   window.scrollTo(0, 0);
  // };

  // const prevPage = () => {
  //   setPage((prevPage) => (prevPage -= 1));
  //   window.scrollTo(0, 0);
  // };

  return (
    <div className=" flex flex-col bg-[url('/src/images/stars.gif')] bg-bafBlack md:flex-row  h-screen min-h-screen  ">
      <AuthBackground />
      {page === 1 && (
        <form
          className={
            "flex min-h-[470px] mt-8   md:rounded-none  rounded-md bg-bafWhite sm:min-h-[600px] md:h-screen md:mt-0 md:w-1/2"
          }
          onSubmit={formik.handleSubmit}
        >
          <Container>
            <div className="flex md:h-full items-center md:px-12 ">
              <div className=" h-[50%]  md:min-w-[350px] lg:min-w-[400px] max-w-[500px] max-h-[500px]">
                <h1 className="mt-8 text-3xl font-semibold">
                  Enter E-mail Address
                </h1>
                <p className="mt-4 text-sm">
                  Don't have an account?
                  <Link
                    to="/sign-in"
                    className="ml-1 shadow-[inset_0_-0.4em_#BFFFBC]"
                  >
                    Sign up
                  </Link>{" "}
                  here.
                </p>
                <fieldset className=" flex flex-col justify-between ">
                  <SignUpInput
                    title="E-mail"
                    value={formik.values.emailId}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    id="emailId"
                    error={
                      (formik.touched.emailId && formik.errors.emailId) || error
                    }
                  />
                </fieldset>
                {/* <p className="text-sm text-errorRed">{error}</p>s */}
                <Link
                  to="/forgot-phone"
                  className="text-xs text-darkPurple italic"
                >
                  Forgot your e-mail?
                </Link>
                <footer className="my-12 flex justify-between mt-24 md:mt-32">
                  <Link to="/login">
                    <HollowButton title="Back" medium className="!w-[120px]" />
                  </Link>
                  <HollowButton
                    title="Next"
                    medium
                    className="!w-[120px]"
                    disabled={disableNextButton}
                  />
                </footer>
              </div>
            </div>
          </Container>
        </form>
      )}
      {/* Third Page */}
      {page === 2 && (
        <section className=" flex min-h-[470px] w-full flex-col justify-center rounded-md bg-bafWhite py-8 md:py-0  sm:min-h-[600px] md:h-[100vh] md:min-h-[0] md:w-1/2 mt-8 md:mt-0">
          <Container>
            <div className="min-h-[350px] md:px-8 flex flex-col max-w-[400px] mx-auto justify-between">
              <div>
                <h1 className="mphone:text-center text-4xl font-bold md:text-left tablet:text-center">
                  Reset Link Sent!
                </h1>
                <p className="mx-auto mt-4 max-w-[300px] text-xs">
                  {success}{" "}
                  <span className="shadow-[inset_0_-0.7em_#BFFFBC]">
                    {email}
                  </span>
                </p>
              </div>
              <footer className="px-4 phone:px-12 md:px-4">
                <HollowButton
                  title="Back"
                  medium
                  handleClick={() => setPage(1)}
                />
              </footer>
            </div>
          </Container>
        </section>
      )}
    </div>
  );
}
