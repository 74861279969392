import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Tooltip from "react-simple-tooltip";
import RemoveAffiliateModal from "../Modals/RemoveAffiliateModal";
import RemoveRepModal from "../Modals/RemoveRepModal";

export default function KebabMenuButton({
  type,
  enableMessageOption,
  enableViewProfileOption,
  disableAffiliateOption,
  disableOption,
  affiliateOption,
  campaignsOption,
  upcomingCampaignOptions,
  shortNotificationOptions,
  affiliateID,
  repID,
  campaignID,
  token,
  setDeleteRefresh,
  campaignStatus,
  following,
}) {
  const [kebabMenuToggle, setKebabMenuToggle] = useState(false);
  const [showRemoveAffiliateModal, setShowRemoveAffiliateModal] =
    useState(false);
  const [showRemoveRepModal, setShowRemoveRepModal] = useState(false);

  const { pathname } = useLocation();

  const handleOnClickKebabMenu = () => {
    setKebabMenuToggle(!kebabMenuToggle);
  };
  // eslint-disable-next-line
  const onClickOutside = () => {
    setKebabMenuToggle(false);
  };

  const ref = useRef(null);

  //* Close drop down menu when user clicks outside
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onClickOutside]);

  const handleDisableCampaign = (campaignID) => {
    axios
      .post(
        `https://api.brandaf.com/v2/dealer/disableCampaign`,
        {
          campaignId: campaignID,
        },
        {
          headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
        }
      )
      .then((res) => {
        alert(res.data.successMsg || res.data.errorMsg);
        setKebabMenuToggle(false);
        setDeleteRefresh({});
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleEnableCampaign = (campaignID) => {
    console.log(campaignID);
    axios
      .post(
        `https://api.brandaf.com/v2/dealer/enableCampaign`,
        {
          campaignId: campaignID,
        },
        {
          headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
        }
      )
      .then((res) => {
        alert(res.data.successMsg || res.data.errorMsg);
        setKebabMenuToggle(false);
        setDeleteRefresh({});
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRemoveAffiliateModal = () => {
    setShowRemoveAffiliateModal((prev) => !prev);
  };

  const handleRemoveRepModal = () => {
    setShowRemoveRepModal((prev) => !prev);
  };

  return (
    <div
      className="KebabMenu relative inline-block ml-auto"
      ref={ref}
      onClickOutside={onClickOutside}
    >
      <RemoveAffiliateModal
        id={affiliateID}
        token={token}
        showModal={showRemoveAffiliateModal}
        handleModal={handleRemoveAffiliateModal}
      />
      <RemoveRepModal
        repID={repID}
        token={token}
        showModal={showRemoveRepModal}
        handleModal={handleRemoveRepModal}
      />
      <button type={type} onClick={() => handleOnClickKebabMenu()}>
        <svg
          clip-rule="evenodd"
          fill-rule="evenodd"
          stroke-linejoin="round"
          stroke-miterlimit="2"
          viewBox="0 0 10 24"
          xmlns="http://www.w3.org/2000/svg"
          className="fill-darkPurple h-8 w-8 cursor-pointer"
        >
          <path d="m12 16.495c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25z" />
        </svg>
      </button>
      <ul
        className={`KebabMenuContent absolute overflow-hidden top-6 right-5 w-[180px] md:w-[200px] z-20 bg-bafWhite rounded-xl shadow-[0px_4px_4px_rgba(0,0,0,0.1),5px_-1px_10px_#F3F3F3] cursor-pointer ${
          kebabMenuToggle ? "block" : "hidden"
        }`}
      >
        {enableMessageOption && <li className="p-4 tracking-wider">Message</li>}
        {disableAffiliateOption && (
          <li
            onClick={() => handleRemoveAffiliateModal()}
            className="p-4 tracking-wider text-[#ff0000] hover:bg-peach border-l-[5px] border-transparent hover:border-[#ff0000]"
          >
            Disable Affiliate
          </li>
        )}

        {disableOption && (
          //* For disabling representatives
          <li
            onClick={() => handleRemoveRepModal()}
            className="p-4 tracking-wider text-[#ff0000] hover:bg-peach border-l-[5px] border-transparent hover:border-[#ff0000]"
          >
            Disable
          </li>
        )}

        {affiliateOption && (
          <>
            {pathname.includes("/single-campaign") ? (
              <>
                {following && following === 1 ? (
                  <li
                    onClick={() => handleRemoveAffiliateModal()}
                    className="p-4 tracking-wider text-[#ff0000] hover:bg-peach hover:border-[#ff0000]"
                  >
                    Disable Affiliate
                  </li>
                ) : null}
              </>
            ) : (
              <li
                onClick={() => handleRemoveAffiliateModal()}
                className="p-4 tracking-wider text-[#ff0000] hover:bg-peach border-l-[5px] border-transparent hover:border-[#ff0000]"
              >
                Disable Affiliate
              </li>
            )}
          </>
        )}

        {campaignsOption && (
          <>
            <Link to={`/single-campaign/${campaignID && campaignID}`}>
              <li className="p-4 tracking-wider hover:bg-lightPurple border-l-[5px] border-transparent hover:border-darkPurple">
                View Campaign
              </li>
            </Link>
            {campaignStatus && campaignStatus === "active" ? (
              <li
                className="p-4 tracking-wider text-[#ff0000] hover:bg-peach border-l-[5px] border-transparent hover:border-[#ff0000]"
                onClick={() => handleDisableCampaign(campaignID && campaignID)}
              >
                Disable
              </li>
            ) : campaignStatus && campaignStatus === "disabled" ? (
              <li
                className="p-4 tracking-wider hover:bg-lightPurple border-l-[5px] border-transparent hover:border-darkPurple"
                onClick={() => handleEnableCampaign(campaignID && campaignID)}
              >
                Enable
              </li>
            ) : null}
          </>
        )}

        {upcomingCampaignOptions && (
          <>
            <Link to={`/single-campaign/${campaignID && campaignID}`}>
              <li className="p-4 tracking-wider border-l-[5px] border-transparent hover:bg-lightPurple hover:border-darkPurple ">
                View Campaign
              </li>
            </Link>
            <Link to={`/edit-campaign/${campaignID && campaignID}`}>
              <li
                className={`p-4 tracking-wider hover:bg-lightPurple border-l-[5px] border-transparent hover:border-darkPurple`}
              >
                Edit Campaign
              </li>
            </Link>
            {campaignStatus && campaignStatus === "active" ? (
              <li
                className="p-4 tracking-wider text-[#ff0000] hover:bg-peach border-l-[5px] border-transparent hover:border-[#ff0000]"
                onClick={() => handleDisableCampaign(campaignID && campaignID)}
              >
                Disable
              </li>
            ) : campaignStatus && campaignStatus === "disabled" ? (
              <li
                className="p-4 tracking-wider hover:bg-lightPurple border-l-[5px] border-transparent hover:border-darkPurple"
                onClick={() => handleEnableCampaign(campaignID && campaignID)}
              >
                Enable
              </li>
            ) : null}
          </>
        )}
        {shortNotificationOptions && (
          <>
            <li className="p-4 tracking-wider hover:bg-lightPurple border-l-[5px] border-transparent hover:border-darkPurple">
              Mark as Read
            </li>
            <li className="p-4 tracking-wider hover:bg-lightPurple border-l-[5px] border-transparent hover:border-darkPurple">
              Bookmark
            </li>
            <li className="p-4 tracking-wider text-[#ff0000] hover:bg-peach border-l-[5px] border-transparent hover:border-[#ff0000]">
              Remove
            </li>
          </>
        )}
      </ul>
    </div>
  );
}
