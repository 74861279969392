import React from "react";
import { useState, useEffect } from "react";
import HollowButton from "../Shared/HollowButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import "yup-phone";
import YupPassword from "yup-password";
import CustomSelect from "../Shared/CustomSelect";
import Checkbox from "../Shared/Checkbox";
import Container from "../Shared/Container";
import Heading from "../Shared/Heading";
import axios from "axios";
import SignUpInput from "../Shared/SignUpInput";
import DragDropFile from "../Shared/DragDropFile";
import SignUpTextArea from "../Shared/SignUpTextArea";
import RadioButton from "../Shared/RadioButton";
import useDropDownValues from "../hooks/useDropDownValues";
import useStateValues from "../hooks/useStateValues";
import spaceship from "../images/starships.gif";

import {
  switchCompanyType,
  switchConversion,
  switchHearAbout,
  switchIndustryType,
  switchRegion,
} from "../helpers/helpers";
import { Link } from "react-router-dom";
import Facebook from "../images/facebook-icon.svg";
import Instagram from "../images/instagram-icon.svg";
import Twitter from "../images/twitter-icon.svg";
import SoftwareCreatorsPolicyModal from "../Modals/SoftwareCreatorsPolicyModal";
import PrivacyAgreementModal from "../Modals/PrivacyAgreementModal";
import TermsOfServiceModal from "../Modals/TermsOfServiceModal";
import Tooltip from "react-simple-tooltip";
import AuthBackground from "../Shared/AuthBackground";

YupPassword(Yup);

export default function SignUp() {
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  const [page, setPage] = useState(1);
  const [showCreatorsPolicy, setCreatorsPolicy] = useState(false);
  const [showPrivacyAgreement, setPrivacyAgreement] = useState(false);
  const [showTermsOfService, setTermsOfService] = useState(false);
  const [checkCreatorsPolicy, setCheckCreatorsPolicy] = useState(false);
  const [checkPrivacyAgreement, setCheckPrivacyAgreement] = useState(false);
  const [checkTermsOfService, setCheckTermsOfService] = useState(false);
  const [fileError, setFileError] = useState("");
  const [imageError, setImageError] = useState("");
  const [responseError, setResponseError] = useState("");
  const [firstPageError, setFirstPageError] = useState('')

  const handleCreatorsPolicy = () => {
    setCreatorsPolicy((prev) => !prev);
  };
  const handlePrivacyAgreement = () => {
    setPrivacyAgreement((prev) => !prev);
  };
  const handleTermsOfService = () => {
    setTermsOfService((prev) => !prev);
  };
  const handleCheckCreatorsPolicy = () => {
    formik.values.creators = true;
  };
  const handleCheckPrivacyAgreement = () => {
    formik.values.privacy = true;
  };
  const handleCheckTermsOfService = () => {
    formik.values.terms = true;
  };

  const nextPage = () => {
    setPage((prevPage) => (prevPage += 1));
    window.scrollTo(0, 0);
  };
  const prevPage = () => {
    setPage((prevPage) => (prevPage -= 1));
    window.scrollTo(0, 0);
  };
  const handleFile = (file) => {
    if (
      !file.name.includes(".jpeg") &&
      !file.name.includes(".jpg") &&
      !file.name.includes(".png")
    ) {
      setFileError("File must be a jpeg, jpg, or png");
      return;
    } else {
      setFileError("");
      setSelectedFile(file);
    }
  };
  useEffect(() => {
    document.title = "Brandaf - Brand Sign-up";
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => {
      URL.revokeObjectURL(objectUrl);
    };
  }, [selectedFile]);
  const rePhoneNumber = "^[0-9]{10}$";
  const reLetters = "^[a-zA-Z]+$";
  const reURL =
    "(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})";

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      question: "",
      password: "",
      confirmPassword: "",
      marketingFirstName: "",
      marketingLastName: "",
      marketingEmail: "",
      marketingPhone: "",
      billingFirstName: "",
      billingLastName: "",
      billingEmail: "",
      billingPhone: "",
      companyName: "",
      companyType: "",
      industryType: "",
      subscription: "",
      employees: "",
      country: "",
      province: "",
      websiteUrl: "",
      tiktok: false,
      youtube: false,
      instagram: false,
      linkedin: false,
      meta: false,
      twitter: false,
      other: false,
      otherLink: "",
      tiktokLink: "",
      youtubeLink: "",
      instagramLink: "",
      linkedinLink: "",
      metaLink: "",
      twitterrLink: "",
      conversion: "",
      affiliate: "",
      referral: "",
      ads: "",
      konect: "No",
      crm: "",
      letUsKnow: "",
      marketing: false,
      creators: false,
      privacy: false,
      terms: false,
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string()
        .required("First name is required.")
        .matches(reLetters, "Name can only contain letters."),
      lastName: Yup.string()
        .required("Last name is required.")
        .matches(reLetters, "Name can only contain letters."),
      email: Yup.string()
        .email("Email isn't valid.")
        .required("Please enter your email address."),
      phone: Yup.string()
        .matches(rePhoneNumber, "Phone number is not valid.")
        .required("Please enter your phone number."),
      password: Yup.string()
        .required("Password is required.")
        .minLowercase(1, "Password must contain one lowercase letter.")
        .minUppercase(1, "Password must contain one uppercase letter.")
        .minSymbols(1, "Password must contain one special character.")
        .minNumbers(1, "Password must contain at least 1 number.")
        .min(8, "Password must have 8 or more characters."),
      confirmPassword: Yup.string().required("Field is required."),
      marketingFirstName: Yup.string()
        .required("First name is required.")
        .matches(reLetters, "Name can only contain letters."),
      marketingLastName: Yup.string()
        .required("Last name is required.")
        .matches(reLetters, "Name can only contain letters."),
      marketingEmail: Yup.string()
        .email("Email isn't valid.")
        .required("Please enter your email address."),
      marketingPhone: Yup.string()
        .matches(rePhoneNumber, "Phone number is not valid.")
        .required("Please enter your phone number."),
      billingFirstName: Yup.string()
        .required("First name is required.")
        .matches(reLetters, "Name can only contain letters."),
      billingLastName: Yup.string()
        .required("Last name is required.")
        .matches(reLetters, "Name can only contain letters."),
      billingEmail: Yup.string()
        .email("Email isn't valid.")
        .required("Please enter your email address."),
      billingPhone: Yup.string()
        .matches(rePhoneNumber, "Phone number is not valid.")
        .required("Please enter your phone number."),
      companyName: Yup.string().required("Company name is required."),
      companyType: Yup.string().required("Company type is required."),
      industryType: Yup.string().required("Industry type is required."),
      subscription: Yup.string().required("Subscription service is required."),
      employees: Yup.string().required("Number of employees is required."),
      country: Yup.string().required("Country is required."),
      province: Yup.string().required("Province is required."),
      letUsKnow: Yup.string().max(500),
      websiteUrl: Yup.string()
        .required("Website URL is required.")
        .matches(reURL, "URL is not valid."),
      otherLink: Yup.string().when("other", {
        is: true,
        then: Yup.string().required("Other Social Media URL is required."),
      }),
      tiktokLink: Yup.string().when("tiktok", {
        is: true,
        then: Yup.string().required("TikTok URL is required."),
      }),
      youtubeLink: Yup.string().when("youtube", {
        is: true,
        then: Yup.string().required("YouTube URL is required."),
      }),
      instagramLink: Yup.string().when("instagram", {
        is: true,
        then: Yup.string().required("Instagram URL is required."),
      }),
      linkedinLink: Yup.string().when("linkedin", {
        is: true,
        then: Yup.string().required("LinkedIn URL is required."),
      }),
      metaLink: Yup.string().when("meta", {
        is: true,
        then: Yup.string().required("Meta is required."),
      }),
      twitterrLink: Yup.string().when("twitter", {
        is: true,
        then: Yup.string().required("Twitter URL is required."),
      }),

      crm: Yup.string().when("konect", {
        is: "Yes",
        then: Yup.string()
          .email("Email isn't valid.")
          .required("Crm email is required."),
      }),
      // .email("Email isn't valid.")
      // .required("Please enter your email address."),
    }),
    onSubmit: (values, { resetForm }) => {
      if (!selectedFile) {
        setImageError("Please upload a Brand Image.");
        return;
      }
      const register =
        `${process.env.REACT_APP_PROD_URL}registerDealerNew`;
      const formData = new FormData();

      const data = {
        firstPage: {
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: values.phone,
          email: values.email,
        },
        secondPage: {
          password: values.password,
          firstName: values.marketingFirstName,
          lastName: values.marketingLastName,
          phoneNumber: values.marketingPhone,
          email: values.marketingEmail,
          billingFname: values.billingFirstName,
          billingLname: values.billingLastName,
          billingPhone: values.billingPhone,
          billingEmail: values.billingEmail,
          tikTokUrl: values.tiktokLink,
          instagramUrl: values.instagramLink,
          metaUrl: values.metaLink,
          twitterUrl: values.twitterrLink,
          youtubeUrl: values.youtubeLink,
          linkedinUrl: values.linkedinLink,
          crm: values.crm,
          companyUrl: values.websiteUrl,
          tiktokPreferred: values.tiktok ? 1 : 0,
          instagramPreferred: values.instagram ? 1 : 0,
          metaPreferred: values.meta ? 1 : 0,
          twitterPreferred: values.twitter ? 1 : 0,
          youtubePreferred: values.youtube ? 1 : 0,
          linkedInPreferred: values.linkedin ? 1 : 0,
          konectCustomer: values.konect === "Yes" ? 1 : 0,
          letUsKnow: values.letUsKnow,
          hearId: switchHearAbout(values.referral),
          otherSocialMediaPreferred: values.otherLink,
          conversionId: switchConversion(values.conversion),
          existingAffProgId: values.affiliate ? 1 : 0,
          companyName: values.companyName,
          countryId: values.country === "Canada" ? 2 : 1,
          fbGoogleAds: values.ads,
          noOfEmployee: values.employees,
          subscriptionTypeService: values.subscription,
          stateId: switchRegion(values.province),
          industryId: switchIndustryType(values.industryType),
          companyTypeId: switchCompanyType(values.companyType),
          otherPreferred: values.other,
        },
      };
      console.log(data);
      formData.append("data", JSON.stringify(data));
      formData.append("companyImage", selectedFile);
      axios
        .post(register, formData, {
          headers: { "Access-Control-Allow-Origin": "*" },
        })
        .then((res) => {
          console.log(res);
          if (res.data.responseCode === 0) {
            nextPage();
          } else {
            setResponseError(res.data.errorMsg);
          }
        });
    },
  });

  console.log(formik.values.konect);

  //* Custom Hooks
  const dropDown = useDropDownValues();

  //* Populate Dropdown Values With API Data

  const companyTypes =
    dropDown.companyTypeList &&
    dropDown.companyTypeList.map((type) => ({
      value: type.companyTypeName,
      label: type.companyTypeName,
    }));

  const industryTypes =
    dropDown.industryList &&
    dropDown.industryList.map((type) => ({
      value: type.industryName,
      label: type.industryName,
    }));

  const countries =
    dropDown.countryList &&
    dropDown.countryList.map((type) => ({
      value: type.countryName,
      label: type.countryName,
    }));

  const conversion =
    dropDown.primaryConversionList &&
    dropDown.primaryConversionList.map((type) => ({
      value: type.coversionName,
      label: type.coversionName,
    }));

  const hearAboutDropdown =
    dropDown.hearAboutList &&
    dropDown.hearAboutList.map((type) => ({
      value: type.hearAboutName,
      label: type.hearAboutName,
    }));

  const subscription = ["Yes", "No"];
  const subscriptionService = subscription.map((val) => ({
    value: val,
    label: val,
  }));
  const employees = ["1-10", "11-50", "51-200", "201-1000", "1000+"];
  const employeeDropDown = employees.map((val, i) => ({
    value: val,
    label: val,
  }));

  const countryId = formik.values.country === "Canada" ? 2 : 1;
  const states = useStateValues(countryId);

  const stateValues =
    states &&
    states.stateList &&
    states.stateList.map((state) => ({
      value: state.stateName,
      label: state.stateName,
    }));

  const affiliate = ["Yes", "No"];
  const affiliateDropdowm = affiliate.map((val) => ({
    value: val,
    label: val,
  }));

  //* Disable Values
  const disableNextButton =
    !formik.values.firstName ||
      !formik.values.lastName ||
      !formik.values.phone ||
      formik.errors.firstName ||
      formik.errors.lastName ||
      !formik.values.email ||
      formik.errors.email ||
      formik.errors.phone
      ? true
      : false;

  const disableSubmitButton =
    formik.errors.password ||
      !formik.values.marketing ||
      !formik.values.creators ||
      !formik.values.terms ||
      !formik.values.privacy
      ? true
      : false;

  const disableCRM =
    formik.values.konect === "No" || !formik.values.konect ? true : false;
  const disableTikTok = formik.values.tiktok ? false : true;
  const disableInstagram = formik.values.instagram ? false : true;
  const disableMeta = formik.values.meta ? false : true;
  const disableTwitter = formik.values.twitter ? false : true;
  const disableYoutube = formik.values.youtube ? false : true;
  const disableLinkedin = formik.values.linkedin ? false : true;
  const disableOther = formik.values.other ? false : true;

  const validateUser = async () => {
    const data = {
      email: formik.values.email,
      phoneNumber: formik.values.phone
    }
    const response = await axios.post("https://api.brandfiliate.net/v2/dealer/checkPhoneEmailUsed", data)
    console.log(response)
    if (response.data.responseCode === -1) {
      setFirstPageError(response.data.errorMsg)
    } else {
      nextPage()
    }
  }

  const resetFormField = (field, resetField) => {
    console.log(field, resetField);
    if (!formik.values[field]) {
      return;
    } else {
      formik.values[resetField] = "";
    }
  };

  const resetCrm = () => {
    formik.values.crm = "";
  };

  const handleCountry = (value) => {
    formik.setFieldValue("country", value.value);
    formik.setFieldValue("province", "");
    formik.values.province = "";
  };
  return (
    <div className=" ] min-h-screen overflow-hidden bg-bafBlack  bg-[url('/src/images/stars.gif')]  bg-center md:flex">
      <AuthBackground />

      <form
        onSubmit={formik.handleSubmit}
        className={` max-h-screen min-h-screen scrollbar-hide ${page === 2 && "overflow-y-scroll"
          } ${page === 1 || page === 2
            ? "flex-1 md:w-1/2 md:bg-bafWhite lg:w-1/2"
            : ""
          }`}
      >
        {/* First Page */}
        {page === 1 && (
          <>
            <section className="my-8 flex items-center rounded-xl bg-bafWhite md:mt-[3.5rem] md:h-screen md:rounded-none  md:px-8 xl:mt-[2rem] xl:mx-[6rem]">
              <Container>
                <div className=" md:-h-[600px]  h-[50%] max-w-[500px]  lg:min-w-[400px]">
                  <h1 className=" text-3xl font-semibold">Sign Up</h1>
                  <p className="mt-4 text-sm">
                    Have an account?{" "}
                    <Link
                      to="/login"
                      className="shadow-[inset_0_-0.6em_#BFFFBC]"
                    >
                      Log in.
                    </Link>{" "}
                  </p>
                  <fieldset className=" flex flex-col justify-between ">
                    <SignUpInput
                      title="First Name"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      id="firstName"
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.firstName && formik.errors.firstName
                      }
                    />
                    <SignUpInput
                      title="Last Name"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      id="lastName"
                      onBlur={formik.handleBlur}
                      error={formik.touched.lastName && formik.errors.lastName}
                    />
                    <SignUpInput
                      title="Phone Number"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      id="phone"
                      error={formik.touched.phone && formik.errors.phone}
                    />
                    <SignUpInput
                      title="E-mail"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      id="email"
                      error={formik.touched.email && formik.errors.email}
                    />
                  </fieldset>
                  <p className="text-sm text-errorRed">{firstPageError}</p>
                  <div className="mt-8 flex justify-end">
                    <HollowButton
                      title="Next"
                      medium
                      handleClick={validateUser}
                      disabled={disableNextButton}
                      type="button"
                    />
                  </div>
                </div>
              </Container>
            </section>
            {/* <footer className="bg-black fixed inset-x-0 bottom-0 mt-10 h-20"></footer> */}
          </>
        )}

        {/* Second Page */}
        {page === 2 && (
          <section className=" mt-8 rounded-xl bg-bafWhite md:my-[8rem] md:px-4 lg:px-8 xl:mx-[6rem]">
            <Container>
              <Heading>Hey {formik.values.firstName}!</Heading>
              <p className="text-xs sm:text-sm">
                Let's get you started and create your account.
              </p>
              {/* Password */}
              <SignUpInput
                type="password"
                title="Password"
                id="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.password}
                error={formik.touched.password && formik.errors.password}
              />
              <SignUpInput
                type="password"
                title="Re-Type Password"
                id="confirmPassword"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                error={
                  formik.touched.password &&
                  formik.values.password !== formik.values.confirmPassword &&
                  "Passwords don't match"
                }
              />
              <p className="py-4">Password must have the following:</p>
              <ul className="list-disc">
                <li className="ml-8 tracking-wider">
                  length of 8 or more characters
                </li>
                <li className="ml-8 tracking-wider">one lowercase letter</li>
                <li className="ml-8 tracking-wider">one uppercase letter</li>
                <li className="ml-8 tracking-wider">one numerical</li>
                <li className="ml-8 tracking-wider">one special character</li>
              </ul>

              {/* Marketing */}
              <section className="MarketingContact">
                <Heading>Marketing Contact</Heading>
                <p className="text-xs sm:text-sm">
                  Who do we contact for marketing purposes?
                </p>
                <fieldset className=" flex  flex-col justify-evenly">
                  <SignUpInput
                    title="First Name"
                    value={formik.values.marketingFirstName}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    id="marketingFirstName"
                    error={
                      formik.touched.marketingFirstName &&
                      formik.errors.marketingFirstName
                    }
                  />
                  <SignUpInput
                    title="Last Name"
                    value={formik.values.marketingLastName}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    id="marketingLastName"
                    error={
                      formik.touched.marketingLastName &&
                      formik.errors.marketingLastName
                    }
                  />

                  <SignUpInput
                    title="Phone Number"
                    value={formik.values.marketingPhone}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    id="marketingPhone"
                    error={
                      formik.touched.marketingPhone &&
                      formik.errors.marketingPhone &&
                      "Phone isn't' valid"
                    }
                  />
                  <SignUpInput
                    title="E-mail"
                    value={formik.values.marketingEmail}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    id="marketingEmail"
                    error={
                      formik.touched.marketingEmail &&
                      formik.errors.marketingEmail
                    }
                  />
                </fieldset>
              </section>

              {/* Billing */}
              <section className="BillingContact ">
                <Heading>Billing Contact</Heading>
                <p className="text-xs sm:text-sm">
                  Who do we contact for billing purposes?
                </p>
                <fieldset className="flex flex-col justify-evenly">
                  <SignUpInput
                    title="First Name"
                    value={formik.values.billingFirstName}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    id="billingFirstName"
                    error={
                      formik.touched.billingFirstName &&
                      formik.errors.billingFirstName
                    }
                  />
                  <SignUpInput
                    title="Last Name"
                    value={formik.values.billingLastName}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    id="billingLastName"
                    error={
                      formik.touched.billingLastName &&
                      formik.errors.billingLastName
                    }
                  />

                  <SignUpInput
                    title="Phone Number"
                    value={formik.values.billingPhone}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    id="billingPhone"
                    error={
                      formik.touched.billingPhone &&
                      formik.errors.billingPhone &&
                      "Phone isn't valid"
                    }
                  />
                  <SignUpInput
                    title="E-mail"
                    value={formik.values.billingEmail}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    id="billingEmail"
                    error={
                      formik.touched.billingEmail && formik.errors.billingEmail
                    }
                  />
                </fieldset>
              </section>

              {/* Brand Sign Up */}
              <section className="BrandSignUp">
                <Heading>Brand Sign Up</Heading>
                <p className="mb-8 text-xs sm:text-sm">
                  Let's register your brand with Brandaf.{" "}
                </p>
                <DragDropFile
                  handleFile={(file) => handleFile(file)}
                  preview={preview}
                  title={`Drag image here or Upload Image`}
                  fileName={fileError}
                  acceptedFileType=".jpg,.jpeg,.png"
                />
                <fieldset className="">
                  <SignUpInput
                    title="Company Name"
                    value={formik.values.companyName}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    id="companyName"
                    error={
                      formik.touched.companyName && formik.errors.companyName
                    }
                  />

                  <CustomSelect
                    labelClassName="text-xs"
                    options={companyTypes}
                    value={formik.values.companyType}
                    onBlur={formik.handleBlur}
                    label="Company Type"
                    onChange={(value) =>
                      formik.setFieldValue("companyType", value.value)
                    }
                    error={
                      formik.touched.companyType && formik.errors.companyType
                    }
                    className="mt-8"
                  />

                  <CustomSelect
                    labelClassName="text-xs"
                    options={industryTypes}
                    value={formik.values.industryType}
                    onBlur={formik.handleBlur}
                    label="Industry Type"
                    onChange={(value) =>
                      formik.setFieldValue("industryType", value.value)
                    }
                    error={
                      formik.touched.industryType && formik.errors.industryType
                    }
                    className="mt-8"
                  />

                  <CustomSelect
                    labelClassName="text-xs"
                    options={subscriptionService}
                    value={formik.values.subscription}
                    onBlur={formik.handleBlur}
                    label="Do You Offer a Subscription Type Service?"
                    onChange={(value) =>
                      formik.setFieldValue("subscription", value.value)
                    }
                    error={
                      formik.touched.subscription && formik.errors.subscription
                    }
                    className="mt-8"
                  />

                  <CustomSelect
                    labelClassName="text-xs"
                    options={employeeDropDown}
                    value={formik.values.employees}
                    onBlur={formik.handleBlur}
                    label="Number of Employees"
                    onChange={(value) =>
                      formik.setFieldValue("employees", value.value)
                    }
                    error={formik.touched.employees && formik.errors.employees}
                    className="mt-8"
                  />

                  <CustomSelect
                    labelClassName="text-xs"
                    options={countries}
                    value={formik.values.country}
                    onBlur={formik.handleBlur}
                    label="Country"
                    onChange={(value) => handleCountry(value)}
                    error={formik.touched.country && formik.errors.country}
                    className="mt-8"
                  />

                  <CustomSelect
                    labelClassName="text-xs"
                    options={stateValues}
                    value={formik.values.province}
                    onBlur={formik.handleBlur}
                    label="Region"
                    onChange={(value) =>
                      formik.setFieldValue("province", value.value)
                    }
                    error={formik.touched.province && formik.errors.province}
                    className="mt-8 "
                  />

                  <SignUpInput
                    title="Website URL"
                    value={formik.values.websiteUrl}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    id="websiteUrl"
                    error={
                      formik.touched.websiteUrl && formik.errors.websiteUrl
                    }
                  />
                </fieldset>
                <p className="mt-8">Preferred Social Media Platforms</p>
                <div className="mt-4 grid min-h-[250px] grid-cols-2 gap-4">
                  <Checkbox
                    name="tiktok"
                    value={formik.values.tiktok}
                    onChange={formik.handleChange}
                    label="TikTok"
                    roundedCorners
                    backgroundColor="transparent"
                    borderColor="darkTeal"
                    onClick={() => resetFormField("tiktok", "tiktokLink")}
                  />
                  <Checkbox
                    name="youtube"
                    value={formik.values.youtube}
                    onChange={formik.handleChange}
                    label="Youtube"
                    roundedCorners
                    backgroundColor="transparent"
                    borderColor="darkTeal"
                    onClick={() => resetFormField("youtube", "youtubeLink")}
                  />

                  <Checkbox
                    name="instagram"
                    value={formik.values.instagram}
                    onChange={formik.handleChange}
                    label="Instagram"
                    roundedCorners
                    backgroundColor="transparent"
                    borderColor="darkTeal"
                    onClick={() => resetFormField("instagram", "instagramLink")}
                  />

                  <Checkbox
                    name="linkedin"
                    value={formik.values.linkedin}
                    onChange={formik.handleChange}
                    label="LinkedIn"
                    roundedCorners
                    backgroundColor="transparent"
                    borderColor="darkTeal"
                    onClick={() => resetFormField("linkedin", "linkedinLink")}
                  />

                  <Checkbox
                    name="meta"
                    value={formik.values.meta}
                    onChange={formik.handleChange}
                    label="Meta"
                    roundedCorners
                    backgroundColor="transparent"
                    borderColor="darkTeal"
                    onClick={() => resetFormField("meta", "metaLink")}
                  />

                  <Checkbox
                    name="other"
                    value={formik.values.other}
                    onChange={formik.handleChange}
                    label="Other"
                    roundedCorners
                    backgroundColor="transparent"
                    borderColor="darkTeal"
                    onClick={() => resetFormField("other", "otherLink")}
                  />

                  <Checkbox
                    name="twitter"
                    value={formik.values.twitter}
                    onChange={formik.handleChange}
                    label="Twitter"
                    roundedCorners
                    backgroundColor="transparent"
                    borderColor="darkTeal"
                    onClick={() => resetFormField("twitter", "twitterrLink")}
                  />
                </div>
                <div className="col-span-2">
                  <SignUpInput
                    title="Other Social Media"
                    value={formik.values.otherLink}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    id="otherLink"
                    disabled={disableOther}
                    error={formik.touched.otherLink && formik.errors.otherLink}
                  />
                </div>

                {/* Social Links */}
                <div className="my-8 grid min-h-[300px] grid-cols-2 gap-4">
                  <SignUpInput
                    title="TikTok"
                    value={formik.values.tiktokLink}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    id="tiktokLink"
                    disabled={disableTikTok}
                    error={
                      formik.touched.tiktokLink && formik.errors.tiktokLink
                    }
                  />

                  <SignUpInput
                    title="Instagram"
                    value={formik.values.instagramLink}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    id="instagramLink"
                    disabled={disableInstagram}
                    error={
                      formik.touched.instagramLink &&
                      formik.errors.instagramLink
                    }
                  />

                  <SignUpInput
                    title="Meta"
                    value={formik.values.metaLink}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    id="metaLink"
                    disabled={disableMeta}
                    error={formik.touched.metaLink && formik.errors.metaLink}
                  />

                  <SignUpInput
                    title="Twitter"
                    value={formik.values.twitterrLink}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    id="twitterrLink"
                    disabled={disableTwitter}
                    error={
                      formik.touched.twitterrLink && formik.errors.twitterrLink
                    }
                  />

                  <SignUpInput
                    title="Youtube"
                    value={formik.values.youtubeLink}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    id="youtubeLink"
                    disabled={disableYoutube}
                    error={
                      formik.touched.youtubeLink && formik.errors.youtubeLink
                    }
                  />

                  <SignUpInput
                    title="Linkedin"
                    value={formik.values.linkedinLink}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    id="linkedinLink"
                    disabled={disableLinkedin}
                    error={
                      formik.touched.linkedinLink && formik.errors.linkedinLink
                    }
                  />
                </div>
                <div className="mt-8">
                  <CustomSelect
                    labelClassName="text-xs"
                    options={conversion}
                    value={formik.values.conversion}
                    onBlur={formik.handleBlur}
                    label="Primary Method of Conversion"
                    onChange={(value) =>
                      formik.setFieldValue("conversion", value.value)
                    }
                    error={formik.errors.conversion}
                    className="mt-8"
                  />

                  <CustomSelect
                    labelClassName="text-xs"
                    options={affiliateDropdowm}
                    value={formik.values.affiliate}
                    onBlur={formik.handleBlur}
                    label="Existing Affiliate Program"
                    onChange={(value) =>
                      formik.setFieldValue("affiliate", value.value)
                    }
                    error={formik.errors.affiliate}
                    className="mt-8"
                  />

                  <CustomSelect
                    labelClassName="text-xs"
                    options={hearAboutDropdown}
                    value={formik.values.referral}
                    onBlur={formik.handleBlur}
                    label="How did you hear about Brandaf"
                    onChange={(value) =>
                      formik.setFieldValue("referral", value.value)
                    }
                    error={formik.errors.referral}
                    className="mt-8"
                  />

                  <SignUpTextArea label="Facebook Ad/Google Ads" id="ads" />

                  <p>Are you a Konect.ai customer?</p>
                  <div className="mt-4 mb-12 flex max-w-[220px] justify-between">
                    <RadioButton
                      id="yes"
                      name="konect"
                      value="Yes"
                      onChange={formik.handleChange}
                      label="Yes"
                      roundedCorners
                      backgroundColor="transparent"
                      borderColor="darkTeal"
                    />
                    <RadioButton
                      id="no"
                      name="konect"
                      value="No"
                      onChange={formik.handleChange}
                      label="No"
                      roundedCorners
                      backgroundColor="transparent"
                      borderColor="darkTeal"
                      onClick={() => resetCrm()}
                      checked={formik.values.konect === "No"}
                    />
                  </div>

                  <SignUpInput
                    title="CRM E-mail"
                    value={formik.values.crm}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    disabled={disableCRM}
                    id="crm"
                    error={formik.touched.crm && formik.errors.crm}
                  />

                  <SignUpTextArea
                    label="Additional Questions or Comments?"
                    maxLength={501}
                    value={formik.values.letUsKnow}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    disabled={disableCRM}
                    id="letUsKnow"
                    error={formik.touched.letUsKnow && formik.errors.letUsKnow}
                  />
                </div>
              </section>

              {/* Terms of Service */}
              <section className="TermsOfService mt-[90px]">
                <Heading>Terms of Service</Heading>
                <div className="mb-20 flex flex-col gap-8">
                  <Checkbox
                    name="marketing"
                    label={
                      <label className="text-xs lg:text-sm">
                        I accept future marketing from Brandaf
                      </label>
                    }
                    onChange={formik.handleChange}
                    value={formik.values.marketing}
                    roundedCorners
                    backgroundColor="transparent"
                    borderColor="darkTeal"
                  />

                  <Checkbox
                    name="creators"
                    checked={formik.values.creators}
                    label={
                      <label className="text-xs lg:text-sm">
                        I agree to the{" "}
                        <span className="underline">
                          {" "}
                          <button
                            className="shadow-[inset_0_-0.6em_#BFFFBC]"
                            onClick={handleCreatorsPolicy}
                          >
                            {" "}
                            Brandaf Creators Policy{" "}
                          </button>{" "}
                        </span>{" "}
                      </label>
                    }
                    onChange={formik.handleChange}
                    value={formik.values.creators}
                    roundedCorners
                    backgroundColor="transparent"
                    borderColor="darkTeal"
                  />

                  <Checkbox
                    name="privacy"
                    checked={formik.values.privacy}
                    label={
                      <label className="text-xs lg:text-sm">
                        I agree to the{" "}
                        <span className="underline">
                          {" "}
                          <button
                            className="shadow-[inset_0_-0.6em_#BFFFBC]"
                            onClick={handlePrivacyAgreement}
                          >
                            {" "}
                            Privacy Agreements{" "}
                          </button>{" "}
                        </span>{" "}
                      </label>
                    }
                    onChange={formik.handleChange}
                    value={formik.values.privacy}
                    roundedCorners
                    backgroundColor="transparent"
                    borderColor="darkTeal"
                  />

                  <Checkbox
                    name="terms"
                    checked={formik.values.terms}
                    label={
                      <label className="text-xs lg:text-sm">
                        I have agreed to the{" "}
                        <span className="underline">
                          {" "}
                          <button
                            className="shadow-[inset_0_-0.6em_#BFFFBC]"
                            onClick={handleTermsOfService}
                          >
                            {" "}
                            Terms of Service{" "}
                          </button>{" "}
                        </span>{" "}
                      </label>
                    }
                    onChange={formik.handleChange}
                    value={formik.values.terms}
                    roundedCorners
                    backgroundColor="transparent"
                    borderColor="darkTeal"
                  />
                </div>
                <p className="mb-4 text-sm text-errorRed">{imageError}</p>
                <p className="mb-4 text-sm text-errorRed">{responseError}</p>
              </section>
              <SoftwareCreatorsPolicyModal
                showModal={showCreatorsPolicy}
                handleModal={setCreatorsPolicy}
                showCheck={checkCreatorsPolicy}
                handleCheck={handleCheckCreatorsPolicy}
              />
              <PrivacyAgreementModal
                showModal={showPrivacyAgreement}
                handleModal={setPrivacyAgreement}
                showCheck={checkPrivacyAgreement}
                handleCheck={handleCheckPrivacyAgreement}
              />
              <TermsOfServiceModal
                showModal={showTermsOfService}
                handleModal={setTermsOfService}
                showCheck={checkTermsOfService}
                handleCheck={handleCheckTermsOfService}
              />
              <div className="flex justify-between gap-4">
                <HollowButton
                  title="Back"
                  handleClick={prevPage}
                  className="w-[150px]"
                  medium
                />
                {disableSubmitButton ? (
                  <Tooltip
                    className="text-xs"
                    content="Please fill out all fields."
                    background="#fff"
                    color="black"
                    radius={10}
                    padding={12}
                  >
                    <HollowButton
                      title="Submit"
                      className="w-[150px]"
                      disabled={disableSubmitButton}
                      medium
                    />
                  </Tooltip>
                ) : (
                  <div className>
                    <HollowButton
                      title="Submit"
                      className="w-[150px]"
                      disabled={disableSubmitButton}
                      medium
                    />
                  </div>
                )}
              </div>
            </Container>
            {/* Second page ends */}
          </section>
        )}
        {/* Third Page */}
        {page === 3 && (
          <section className=" flex h-full min-h-[450px] w-full  flex-col justify-center rounded-md bg-bafWhite p-4 sm:mt-8 sm:min-h-[500px] md:mt-0 md:w-[50vw]">
            <div className="flex max-w-[400px] flex-col md:ml-8 lg:mx-20">
              <h1 className=" text- text-4xl font-bold">Request Sent!</h1>
              <p className=" mt-4 max-w-[350px]">
                We will notify you by e-mail with instructions when your brand
                is ready!{" "}
              </p>
              <p className=" mt-4 max-w-[350px]">
                For now, you can follow our journey on Social Media:{" "}
              </p>
              <footer className="mt-6 flex max-w-[120px] items-center justify-between">
                <a
                  href="https://www.facebook.com/wearebrandaf"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img src={Facebook} alt="facebook" />{" "}
                </a>
                <a
                  href="https://www.instagram.com/wearebrandaf/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img src={Instagram} alt="instagram" />{" "}
                </a>
                <a
                  href="https://twitter.com/wearebrandaf"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img src={Twitter} alt="twitter" />{" "}
                </a>
              </footer>
            </div>
          </section>
        )}
      </form>
    </div>
  );
}
