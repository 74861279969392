import React from "react";
import { formatFullDate } from "../helpers/helpers";
import BrandAvatar from "../images/brandaf-logo-small.svg";

export default function AccountStatement({ statement }) {
  const { imageLink, amount, header, description, createTs } = statement;

  return (
    <div className="AccountStatement mt-4 mb-6 flex justify-between">
      <div className="flex items-center">
        <div className="flex flex-shrink-0 h-10 w-10 phone:h-12 phone:w-12 items-center justify-center mr-2 sm:mr-5 overflow-hidden rounded-full border border-coolGrey">
          <img
            className="h-10 w-10 rounded-full"
            src={imageLink || BrandAvatar}
            alt="avatar"
          />
        </div>
        <div className="w-[80px] mphone:w-[120px] phone:w-[175px] sm:w-[80%] md:w-[150px] tablet:w-[180px] bigtab:w-[230px] lg:w-[250px]">
          <p className="truncate text-xs  capitalize text-darkPurple pr-6 phone:pr-0 sm:max-w-[326px] bigtab:max-w-[270px]">
            {description}
          </p>
          <p className="truncate py-0.5 font-bold capitalize text-sm">
            BrandAf
          </p>
          <p className="text-xs text-bafGrey truncate">
            {formatFullDate(createTs)}
          </p>
        </div>
      </div>

      <p className="text-xl md:text-xl text-darkGreen text-right ">
        {`+$${amount?.toLocaleString("en-US", { minimumFractionDigits: 2 })}`}
      </p>
    </div>
  );
}
