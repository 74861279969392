import React from "react";

export default function RadioButton({
  name,
  value,
  onChange,
  label,
  round,
  roundedCorners,
  backgroundColor,
  borderColor,
  id,
  onClick,
  checked,
}) {
  return (
    <div className={`RadioButton max-w-fit`}>
      <input
        checked={checked}
        onClick={onClick}
        type="radio"
        className={`Checkbox border-2 text-darkTeal border-${
          borderColor || ""
        } bg-${backgroundColor || "[#E5E5E5]"} ${round && "rounded-full"} ${
          roundedCorners && "rounded-md"
        } mr-2 h-6 w-6`}
        name={name}
        value={value}
        id={id}
        onChange={onChange}
      />
      <label for={id}>{label}</label>
    </div>
  );
}
