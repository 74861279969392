import React from "react";
import { twMerge } from "tailwind-merge";

export default function HollowButton({
  title,
  handleClick,
  small,
  color,
  medium,
  large,
  mediumLarge,
  fullWidth,
  background,
  type,
  className,
  disabled,
  backgroundHover,
  border,
  tooltip,
  mid,
}) {
  const hollowBtnClassName = twMerge(
    `HollowButton z-0 border-3  transition-all min-w-[120px] duration-200 border ${
      border ? `border-${border}` : "border-bafBlack"
    }  rounded-full font-bold text-[12px] md:text-[13px] text-center py-1  px-2 md:px-4 md:whitespace-nowrap max-w-[250px] flex-[1_0_40%] 
    ${
      disabled
        ? "border-darkGray text-darkGray"
        : `bg-${background} text-${color} ${
            backgroundHover
              ? "hover:bg-darkPurple hover:border-none hover:text-bafWhite"
              : "hover:bg-bafBlack hover:text-bafWhite hover:border-bafWhite"
          }`
    }
    ${className || ""}
    
    ${small && "max-w-[120px] h-9"}
    ${medium && "  min-w-[130px] h-12 md:h-14 max-w-[160px] "}
    ${large && "w-[250px] h-12 md:h-14"}
    ${fullWidth && "w-full"}
    ${mediumLarge && "h-12 md:h-14"}
    ${
      mid &&
      "max-w-[120px] sm:max-w-[140px] md:max-w-[200px] text-[10px] sm:text-xs"
    }
    `
  );

  return (
    <button
      type={type}
      disabled={disabled}
      onClick={handleClick}
      className={hollowBtnClassName}
      data-tip={tooltip && "Please fill all fields and save payment info"}
    >
      {title}
    </button>
  );
}
