import axios from "axios";
import { React, useContext } from "react";
import { useEffect } from "react";
import AuthContext from "../Authentication/auth-context";
import { useNavigate } from "react-router-dom";
import HollowButton from "../Shared/HollowButton";

export default function RemoveAffiliateModal({
  id,
  showModal,
  handleModal,
  token,
}) {
  const authCtx = useContext(AuthContext);
  let navigate = useNavigate();

  const toggleModal = () => {
    handleModal();
  };

  const removeAffiliate = () => {
    axios
      .post(
        `https://api.brandaf.com/v2/dealer/removeAffiliate`,
        {
          affiliateId: id,
        },
        {
          headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
        }
      )
      .then((res) => {
        console.log(res);
        alert(res.data.successMsg || res.data.errorMsg);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {showModal ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-bafGrey bg-opacity-30 outline-none focus:outline-none backdrop-brightness-[0.2]">
            <div className="relative my-6 mx-auto h-[70%] md:h-[60%] w-[70%] min-w-[350px] max-w-[750px] max-h-[600px] rounded-[30px] bg-bafBlack">
              <div className="bg-black relative h-full flex justify-center w-full flex-col rounded-[30px] border-0 shadow-lg outline-none focus:outline-none">
                <div className="flex h-[400p] md:h-[300px] flex-col justify-evenly items-center p-5">
                  <h3 className="text-center text-[40px] font-semibold text-bafWhite">
                    Remove Affiliate?
                  </h3>
                  <p className="mt-2 text-xl text-bafWhite w-[70%] md:w-[68%] md:mt-12 text-center">
                    This affiliate will no longer be notified by your brand's
                    campaigns and updates. This action cannot be undone.
                  </p>

                  <button
                    className="text-black float-right border-0 bg-transparent"
                    onClick={() => toggleModal()}
                  >
                    <span className="opacity-7 bg-black-400 absolute top-3 right-4 block h-6 w-6 py-0 text-3xl text-bafWhite font-bold">
                      x
                    </span>
                  </button>
                </div>
                <div className="flex flex-col items-center justify-evenly p-6 gap-8 md:flex-row">
                  <HollowButton
                    title="Remove"
                    color="bafBlack"
                    background="bafWhite"
                    backgroundHover
                    type="button"
                    handleClick={() => removeAffiliate()}
                    className="min-h-[56px] rounded-full w-[176px]"
                  />
                  <HollowButton
                    title="Cancel"
                    color="bafWhite"
                    type="button"
                    border="bafWhite"
                    handleClick={() => toggleModal()}
                    className="hover:bg-bafWhite hover:text-bafBlack min-h-[56px] rounded-full w-[176px]"
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
