import { useState, useEffect, useContext } from "react";

import { useFormik } from "formik";
import SignUpInput from "../Shared/SignUpInput";
import Container from "../Shared/Container";
import HollowButton from "../Shared/HollowButton";
import axios from "axios";
import * as Yup from "yup";
import AuthContext from "../Authentication/auth-context";
import { Link } from "react-router-dom";
import YupPassword from "yup-password";
import "react-toggle/style.css";
import spaceship from "../images/starships.gif";
import AuthBackground from "../Shared/AuthBackground";

export default function LoginPage() {
  const authCtx = useContext(AuthContext);
  const [error, setError] = useState("");
  useEffect(() => {
    document.title = "Brandaf - Brand Login";
  }, []);
  const login = `${process.env.REACT_APP_PROD_URL}dealerLogin`;
  YupPassword(Yup);

  const formik = useFormik({
    initialValues: {
      emailId: "",
      password: "",
    },
    validationSchema: Yup.object({
      emailId: Yup.string()
        .email("Email isn't valid")
        .required("Please enter your e-mail address"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      axios
        .post(login, values, {
          headers: { "Access-Control-Allow-Origin": "*" },
        })
        .then((res) => {
          const user = {
            companyName: res.data.response.companyName,
            firstName: res.data.response.firstName,
            admin: !!res.data.response.admin,
            image: res.data.response.userPhotoUrl,
            industry: res.data.response.industryName,
            country: res.data.response.countryName,
            region: res.data.response.stateName,
          };
          console.log(res.data);
          if (res.data.responseCode === 0) {
            authCtx.login(res.data.response.token);
            authCtx.handleUser(user);
            window.location.reload();
          } else {
            setError(res.data.errorMsg);
          }
        });
    },
  });

  return (
    <div className=" flex min-h-screen flex-col pb-10 sm:pb-0 bg-[url('/src/images/stars.gif')] bg-bafBlack md:flex-row ">
      <AuthBackground />

      <form
        className={
          "flex rounded-xl bg-bafWhite my-8    sm:min-h-[600px] md:h-screen md:my-0 md:rounded-none md:w-1/2 overflow-y-scroll "
        }
        onSubmit={formik.handleSubmit}
      >
        <Container>
          <div className="flex md:h-full items-center  md:px-8 ">
            <div className="flex-1 h-[50%]   lg:min-w-[400px] max-w-[500px] md:-h-[600px] xl:mx-[6rem]">
              <h1 className=" text-3xl font-semibold">Log In</h1>
              <p className="mt-4 text-sm">
                Don't have an account?
                <Link
                  to="/sign-in"
                  className="ml-1 shadow-[inset_0_-0.4em_#BFFFBC]"
                >
                  Sign up
                </Link>{" "}
                here.
              </p>
              <fieldset className=" flex flex-col justify-between ">
                <SignUpInput
                  title="E-mail"
                  value={formik.values.emailId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  id="emailId"
                  error={formik.touched.emailId && formik.errors.emailId}
                />
                <SignUpInput
                  type="password"
                  title="Password"
                  id="password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  error={formik.touched.password && formik.errors.password}
                />
              </fieldset>
              <p className="text-sm text-errorRed transition-all">{error}</p>
              <div className="mt-8  mb-1 ">
                <label
                  for="red-toggle"
                  class="relative mr-5 inline-flex cursor-pointer items-center"
                >
                  <input
                    type="checkbox"
                    value=""
                    id="red-toggle"
                    class="peer sr-only"
                  />
                  <div
                    class="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:top-0.5 after:left-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:bg-no-repeat after:bg-[left_2.5px_top_2.5px] after:content-[''] after:bg-[url(./images/toggle-cross.svg)] peer-checked:after:bg-[url(./images/toggle-checkmark-mint.svg)] peer-checked:bg-darkTeal 
                  peer-checked:after:bg-[#C6F3EB] peer-checked:after:translate-x-full peer-checked:after:border-[#C6F3EB]  dark:border-gray-600 dark:bg-gray-700 "
                  ></div>
                  <span class="ml-3 text-sm  text-gray-900 dark:text-gray-300">
                    Stay Signed in
                  </span>
                </label>
              </div>
              <Link
                to="/forgot-email"
                className="text-xs text-darkPurple italic"
              >
                Lost your credentials?
              </Link>
              <footer className="my-12 md:h-[100px] mr-6 flex justify-end">
                <HollowButton title="Next" medium className="w-[150px]" />
              </footer>
            </div>
          </div>
        </Container>
      </form>
    </div>
  );
}
