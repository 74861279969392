import React from "react";

export default function Checkbox({
  name,
  value,
  onChange,
  label,
  round,
  roundedCorners,
  backgroundColor,
  borderColor,
  onClick,
  checked,
  purple,
  teal,
  disabled,
}) {
  console.log(borderColor);

  return (
    <div className={`Checkbox max-w-fit`}>
      <input
        onClick={onClick}
        type="checkbox"
        className={`Checkbox outline-none focus:outline-none focus:ring-0 ${
          purple ? "text-darkPurple" : "text-darkTeal "
        }  border-2 ${teal && "border-darkTeal"} border-${
          borderColor || ""
        } bg-${backgroundColor || "[#E5E5E5]"} ${round && "rounded-full"} ${
          roundedCorners && "rounded-md"
        } mr-2 h-6 w-6`}
        name={name}
        value={value}
        id={name}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
      <label for={name}>{label}</label>
    </div>
  );
}
