import axios from "axios";
import  { useEffect } from "react";
import { useState } from "react";

export default function useStateValues(id) {
  const [states, setStates] = useState([]);

  useEffect(() => {
    axios
      .get(`https://api.brandaf.com/v2/dealer/getStates/countryId/${id}`)
      .then((res) => setStates(res.data.response));
  }, [id]);
  return states;
}
