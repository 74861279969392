import React from "react";

import UpcomingCampaignCard from "../Cards/UpcomingCampaignCard";
import CampaignCard from "../Cards/CampaignCard";
import StatsCard from "../Cards/StatsCard";
import WalletCard from "../Cards/WalletCard";
import InviteCard from "../Cards/InviteCard/InviteCard";
import AffiliateEarningCard from "../Cards/AffiliateEarningCard";
import AccountStatements from "../Shared/AccountStatements";
import TransactionHistoryCard from "../Cards/TransactionHistoryCard";


export default function Test() {
  return (
    <div className="h-[100vh] flex flex-wrap">
      <CampaignCard />

      <WalletCard />
      <StatsCard />
      <UpcomingCampaignCard />
      <InviteCard />
      <AffiliateEarningCard />
      <AccountStatements />
      <TransactionHistoryCard />


    </div>
  );
}
