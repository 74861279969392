import React, { useState } from "react";
import DownArrow from "../images/down-arrow.svg";
import DownArrowHover from "../images/down-arrow-hover.svg";
import UpArrow from "../images/up-arrow.svg";
import UpArrowHover from "../images/up-arrow-hover.svg";
import NeutralIcon from "../images/neutral.svg";
import NeutralHover from "../images/neutral-hover.svg";
import { SpinnerDotted } from "spinners-react";
import { Link } from "react-router-dom";
import { abbreviateNumber } from "../helpers/helpers";

export default function StatsCard({
  title,
  amount,
  perLeadPayout,
  total,
  upcoming,
  percent,
  leadProgress,
  loading,
  wrapperClass,
}) {
  const ProgressBar = ({ progressPercentage }) => {
    return (
      <div className="h-2 w-[50%] rounded-md border border-[#d7d7d8]">
        <div
          style={{ width: `${progressPercentage}%` }}
          className={`h-full bg-darkGreen group-hover:text-bafWhite group-hover:duration-[0.5s]"`}
        ></div>
      </div>
    );
  };

  return (
    <div
      className={`StatsCard Card group relative z-[1]  h-[142px] w-full max-w-[210px] lg:max-w-[230px] overflow-hidden border-b-2 bg-bafWhite py-5 px-4 
      ${wrapperClass || ""}
      ${
        Math.sign(percent) === (1 || 0) || leadProgress || leadProgress === 0
          ? "border-b-darkGreen after:absolute after:top-0 after:left-1/2 after:z-[-1] after:h-[180%] after:w-[160%] after:origin-bottom after:translate-x-[-50%] after:translate-y-[-10%] after:scale-y-0 after:rounded-full after:bg-darkGreen after:transition-transform after:duration-[0.5s] after:content-[''] after:hover:scale-y-100"
          : Math.sign(percent) === (-1 || -0)
          ? "border-b-lightOrange after:absolute after:top-0 after:left-1/2 after:z-[-1] after:h-[180%] after:w-[160%] after:origin-bottom after:translate-x-[-50%] after:translate-y-[-10%] after:scale-y-0 after:rounded-full after:bg-lightOrange after:transition-transform after:duration-[0.5s] after:content-[''] after:hover:scale-y-100"
          : percent === "0.0"
          ? "border-b-0 after:absolute after:top-0 after:left-1/2 after:z-[-1] after:h-[180%] after:w-[160%] after:origin-bottom after:translate-x-[-50%] after:translate-y-[-10%] after:scale-y-0 after:rounded-full after:bg-bafGrey after:transition-transform after:duration-[0.5s] after:content-[''] after:hover:scale-y-100"
          : !percent && !leadProgress && leadProgress !== 0
          ? "border-b-darkPurple after:absolute after:top-0 after:left-1/2 after:z-[-1] after:h-[180%] after:w-[160%] after:origin-bottom after:translate-x-[-50%] after:translate-y-[-10%] after:scale-y-0 after:rounded-full after:bg-darkPurple after:transition-transform after:duration-[0.5s] after:content-[''] after:hover:scale-y-100"
          : "border-b-0 after:absolute after:top-0 after:left-1/2 after:z-[-1] after:h-[180%] after:w-[160%] after:origin-bottom after:translate-x-[-50%] after:translate-y-[-10%] after:scale-y-0 after:rounded-full after:bg-bafGrey after:transition-transform after:duration-[0.5s] after:content-[''] after:hover:scale-y-100"
      }
`}
    >
      <h5
        className={`md:text-md text-xs group-hover:text-bafWhite group-hover:duration-[0.5s] truncate`}
      >
        {title}
      </h5>

      {loading && (
        <SpinnerDotted className="mt-4" color="#A590E3" enabled={loading} />
      )}

      {!loading && (
        <>
          <p
            className={`text-[40px] leading-[35px] h-[33px] font-medium group-hover:text-bafWhite group-hover:duration-[0.5s] relative mt-2 mb-7`}
          >
            {amount || perLeadPayout || 0}
            {(leadProgress || leadProgress === 0) && total ? (
              <span className="text-xs text-[#A8A8A8] absolute top-1/2 right-0 group-hover:text-bafWhite group-hover:duration-[0.5s]">
                /{abbreviateNumber(total)}
              </span>
            ) : null}
          </p>
          <div
            className={`mt-2 flex justify-between items-center ${
              perLeadPayout && "invisible"
            }`}
          >
            <div
              className={`flex ${
                (leadProgress || leadProgress === 0) &&
                "w-full justify-between items-center"
              }`}
            >
              {(leadProgress || leadProgress === 0) && (
                <>
                  <p className="text-xs text-darkGray group-hover:text-bafWhite group-hover:duration-[0.5s]">
                    Leads Goal
                  </p>
                  <ProgressBar
                    className="w-[40%]"
                    progressPercentage={leadProgress}
                  />{" "}
                </>
              )}
              {percent && (
                <>
                  {Math.sign(percent) === (1 || 0) && (
                    <>
                      <div className="relative w-4">
                        <img
                          className="absolute h-4 group-hover:opacity-0 group-hover:duration-[0.5s]"
                          src={UpArrow}
                          alt="arrow"
                        />{" "}
                        <img
                          className="absolute h-4 opacity-0 group-hover:opacity-100 group-hover:duration-[0.5s]"
                          src={UpArrowHover}
                          alt="arrow"
                        />{" "}
                      </div>
                      <span className="ml-1 text-xs font-extrabold text-darkGreen group-hover:text-lightGreen group-hover:duration-[0.5s]">
                        {percent}%
                      </span>
                    </>
                  )}
                  {Math.sign(percent) === (-1 || -0) && (
                    <>
                      <div className="relative w-4">
                        <img
                          className="absolute h-4 group-hover:opacity-0 group-hover:duration-[0.5s]"
                          src={DownArrow}
                          alt="arrow"
                        />{" "}
                        <img
                          className="absolute h-4 opacity-0 group-hover:opacity-100 group-hover:duration-[0.5s]"
                          src={DownArrowHover}
                          alt="arrow"
                        />{" "}
                      </div>
                      <span
                        className={`ml-1 text-xs font-extrabold text-lightOrange group-hover:text-peach group-hover:duration-[0.5s]`}
                      >
                        {percent}%
                      </span>
                    </>
                  )}
                  {percent === "0.0" && (
                    <>
                      <div className="relative w-4">
                        <img
                          className="absolute h-4 group-hover:opacity-0 group-hover:duration-[0.5s]"
                          src={NeutralIcon}
                          alt="arrow"
                        />{" "}
                        <img
                          className="absolute h-4 opacity-0 group-hover:opacity-100 group-hover:duration-[0.5s]"
                          src={NeutralHover}
                          alt="arrow"
                        />{" "}
                      </div>

                      <span
                        className={`ml-1 text-xs font-extrabold text-bafGrey group-hover:text-bafWhite group-hover:duration-[0.5s]`}
                      >
                        0%
                      </span>
                    </>
                  )}
                </>
              )}
              {upcoming >= 0 && (
                <span className="ml-1 text-xs font-extrabold text-darkPurple group-hover:text-lightPurple group-hover:duration-[0.5s]">
                  {upcoming} Upcoming
                </span>
              )}
            </div>
            {!leadProgress && leadProgress !== 0 && (
              <p
                className={`text-xs text-darkGray group-hover:text-lightGray group-hover:duration-[0.5s] ${
                  title === "Campaigns" && "mr-auto"
                }`}
              >{`${total || 0} Total`}</p>
            )}
          </div>
        </>
      )}
    </div>
  );
}
