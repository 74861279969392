import React, { useState } from "react";
import HollowButton from "../../Shared/HollowButton";
import InviteCardData from "./InviteCardData";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Link } from "react-router-dom";
import axios from "axios";

export default function InviteCard({ affiliateData, repData, token }) {
  const [buttonText, setButtonText] = useState("Invite");

  const percentage =
    ((affiliateData.totalPoints - affiliateData.tierStartPoint) /
      affiliateData.tierEndPoint) *
    100;
  const inviteAffiliate = () => {
    axios
      .post(
        `${process.env.REACT_APP_PROD_URL}inviteAffiliate`,
        {
          affiliateId: affiliateData && affiliateData.affiliateId,
        },
        {
          headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
        }
      )
      .then(
        (res) => {
          console.log(res);
          if (res.data.responseCode === 0) {
            setButtonText("Invited");

            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }
        },
        [token]
      );
  };

  return (
    <div className="InviteCardTabletDesktop relative my-2 w-[300px] rounded-xl bg-bafWhite px-4 py-8 lg:w-[305px]">
      <header className="relative flex items-center">
        <div>
          <div className="relative h-14 w-14 z-[0]">
            <img
              src={affiliateData && affiliateData.userImage}
              alt="astronaut"
              className="absolute z-[1] w-full h-full object-cover object-center rounded-[50%]"
            />
            <img
              src={affiliateData?.tierImage}
              alt="badge"
              className="absolute -right-2 z-10 mt-8 h-[2rem] w-[2rem] rounded-[50%]"
            />
            <div className="absolute h-14 w-14 scale-[1.1] ">
              <CircularProgressbar
                circleRatio={0.85}
                value={percentage}
                styles={buildStyles({
                  trailColor: "#E5E5E5",
                  pathColor: "#16DB93",
                  rotation: 1 / 2 + 1 / 14,
                })}
              />
            </div>
          </div>
        </div>
        {repData && (
          <img src={repData.userPhotoUrl} alt="astronaut" className="h-12" />
        )}
        <div className="ml-4">
          <p className="text-xs text-bafGrey">
            {(affiliateData && affiliateData.stateName) ||
              (repData && repData.stateName)}
            , {""}
            {(affiliateData && affiliateData.countryName) ||
              (repData && repData.countryName)}
          </p>
          <h2 className="max-w-[180px] truncate text-xl font-bold">
            {(affiliateData && affiliateData.firstName) ||
              (repData && repData.firstName)}{" "}
            {(affiliateData && affiliateData.lastName) ||
              (repData && repData.lastName)}
          </h2>
          <p className="text-xs text-darkPurple">
            {affiliateData && affiliateData.tierName}
          </p>
        </div>
      </header>

      <div className="InviteCardDataWrapper mt-6 mb-8 flex justify-between">
        <InviteCardData
          number={(affiliateData && affiliateData.totalLeads) || 0}
          category="Total Leads"
        />
        <InviteCardData
          number={(affiliateData && affiliateData.totalContentShared) || 0}
          category="Total Shares"
        />
        <InviteCardData
          number={
            (affiliateData && affiliateData.totalUniqueCampaignsShared) || 0
          }
          category="Campaigns"
        />
      </div>

      <footer className="mt-6 flex items-center justify-center gap-4">
        <Link
          to={`/affiliate-profile/${
            affiliateData && affiliateData.affiliateId
          }`}
        >
          <HollowButton title="View Profile" small={true} color="black" />
        </Link>
        <HollowButton
          title={buttonText}
          small={true}
          // color="black"
          type="button"
          handleClick={inviteAffiliate}
          background="bafWhite"
          color="bafBlack"
          border="bafBlack"
        />
      </footer>
    </div>
  );
}
