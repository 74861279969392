import React, { useState } from "react";
import HollowButton from "../Shared/HollowButton";
import { ReactComponent as CloseIcon } from "../images/close-button2.svg";

const TermsOfServiceModal = ({
  showModal,
  handleModal,
  showCheck,
  handleCheck,
}) => {
  const toggleModal = () => {
    handleModal();
  };
  const check = () => {
    handleCheck();
    handleModal();
  };
  return (
    <>
      {showModal ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center  bg-bafGrey bg-opacity-30 outline-none focus:outline-none backdrop-brightness-[0.2] backdrop-blur-[2px]">
            <div className="relative my-6 mx-auto h-[70%] w-[70%] min-w-[350px] max-w-[750px] overflow-y-hidden rounded-2xl bg-bafBlack">
              <div className=" relative flex w-full flex-col rounded-lg border-0 shadow-lg outline-none focus:outline-none">
                <div className="flex flex-col justify-center p-5">
                  <h3 className="text-center text-lg font-semibold  text-bafWhite sm:text-3xl">
                    Terms of Service
                  </h3>

                  <button
                    className="absolute right-5"
                    onClick={() => toggleModal()}
                  >
                    <CloseIcon className="w-6 h-6" />
                  </button>
                </div>
              </div>
              <div className="modal-bg flex h-full flex-wrap overflow-y-scroll p-8">
                <p className="mb-4">
                  Welcome to BrandAffiliate LLC (“BrandAF”). This website and
                  the related webpages of BrandAffiliate (collectively referred
                  to as this “Site”) is provided as a service to its visitors
                  and may be used for informational purposes only. Please
                  carefully read and understand these Website Terms and
                  Conditions (the “Terms and Conditions”) before using this
                  Site. By using this Site, you accept and agree to be bound and
                  abide by the following Terms and Conditions and
                  BrandAffiliate’s Privacy Policy. If you do not agree with
                  these Terms and Conditions, you must not access or use this
                  Site. BrandAffiliate recommends that you print a copy of these
                  Terms and Conditions for future reference.
                </p>
                <p className="mb-4">
                  BrandAffiliate reserves the right to change, edit, or delete
                  any documents, information, or other content appearing on the
                  Site or these Terms and Conditions from time to time without
                  notice and in its sole discretion. All changes are effective
                  immediately when posted and apply to all access to, and use
                  of, this Site thereafter. Every time you wish to use the Site,
                  please review these Terms and Conditions to ensure that you
                  understand the terms that may apply to you at that time.
                </p>
                <h3 className="mb-4 text-lg font-bold">Use of Site</h3>
                <p>
                  All rights, including copyright, in this Site are owned by or
                  licensed to Brand Affiliate. This Site and the content
                  provided in this Site, including the text, graphics, button
                  icons, audio and video clips, digital downloads, data
                  compilations and software, may not be copied, reproduced,
                  republished, uploaded, posted, transmitted or distributed
                  without the prior written permission of BrandAffiliate, except
                  that you may download, display and print the materials
                  presented on this Site for your personal, non-commercial use
                  only. You may not use any “robot,” “spider” or other automatic
                  device, or a program, algorithm or methodology having similar
                  processes or functionality, or any manual process, to monitor
                  or copy any of the web pages, data or content found on this
                  Site, in each case without the prior written permission of
                  BrandAffiliate.
                </p>
                <ul className="mb-4">
                  <li className="mb-4">
                    You represent and warrant that BrandAffiliate’s use of your
                    submission does not and will not breach any agreement,
                    violate any law, or infringe any third party’s rights;
                  </li>
                  <li className="mb-4">
                    You represent and warrant that you have all rights to enter
                    into these Terms and Conditions;
                  </li>
                  <li className="mb-4">
                    BrandAffiliate is free to use in any manner all or part of
                    the content of any such communications on an unrestricted
                    basis without the obligation to notify, identify or
                    compensate you or anyone else; and
                  </li>
                  <li className="mb-4">
                    You grant BrandAffiliate all necessary rights, including a
                    waiver of all privacy and moral rights, to use all comments,
                    feedback, information, or materials, in whole or in part, or
                    as a derivative work, without any duty by BrandAffiliate to
                    anyone whatsoever
                  </li>
                  <li className="mb-4">
                    BrandAffiliate does not accept unsolicited ideas, works, or
                    other materials, and you acknowledge that you are
                    responsible for and bear all risk as to the use or
                    distribution of any such ideas, works, or materials.
                  </li>
                  <li className="mb-4">
                    BrandAffiliate expressly prohibits the scraping of email
                    addresses from this Site and expressly opts out of receiving
                    commercial electronic mail messages to BrandAffiliate domain
                    email addresses that were obtained in violation of the
                    foregoing or by use of automatic address-generation
                    software.
                  </li>
                </ul>
                <div className="flex-col">
                  <p className="mb-2">
                    © BrandAffiliate LLC. All rights reserved.
                  </p>
                  <p>Updated on Feb. 11, 2022.</p>
                </div>
                <footer className="flex items-center w-full justify-between my-10 min-h-[100px] rounded-b">
                  <button
                    className="background-transparent mr-1 mb-1 py-2  text-sm font-bold uppercase text-red-500 outline-none focus:outline-none"
                    type="button"
                    onClick={() => toggleModal()}
                  >
                    <HollowButton title="Cancel" color="bafBlack" small />
                  </button>
                  <button
                    className="background-transparent mr-1 mb-1 py-2  text-sm font-bold uppercase text-red-500 outline-none focus:outline-none"
                    type="button"
                    onClick={() => check()}
                  >
                    <HollowButton
                      title="Agree"
                      color="bafWhite"
                      background="bafBlack"
                      small
                      backgroundHover
                    />
                  </button>
                </footer>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default TermsOfServiceModal;
