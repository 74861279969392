import React from "react";

export default function InviteCardData({ number, category }) {
  return (
    <div className="flex items-center flex-col md:gap-1 text-center md:justify-center">
      <h5 className="font-bold text-lg md:text-[20px]">{number}</h5>
      <p className="text-[10px] text-bafGrey md:text-[12px] lg:text-[10px]">{category}</p>
    </div>
  );
}
