import React, {  Suspense } from "react";
import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { SpinnerDotted } from "spinners-react";

import SignUp from "./Authentication/SignUp";
import ForgotEmail from "./Pages/ForgotEmail";
import ForgotPhone from "./Pages/ForgotPhone";
import LoginPage from "./Pages/LoginPage";


import ResetPassword from "./Pages/ResetPassword";
import Test from "./Pages/Test";

import Header from "./Shared/Header";
import LoadScreen from "./Shared/LoadScreen";

//* CODE SPLITTING
const OverviewPage = React.lazy(() => import("./Pages/OverviewPage"));
const NotificationsPage = React.lazy(() => import("./Pages/NotificationsPage"));
const EditCampaignPage = React.lazy(() => import("./Pages/EditCampaignPage"));
const OtherRepPage = React.lazy(() => import("./Pages/OtherRepPage"));
const RepCampaignPage = React.lazy(() => import("./Pages/RepCampaignPage"));
const OtherRepCampaignPage = React.lazy(() => import("./Pages/OtherRepCampaignsPage"));
const OtherRepAffiliatesPage = React.lazy(() =>
  import("./Pages/OtherRepAffiliatesPage")
);
const BrandAdminPage = React.lazy(() => import("./Pages/BrandAdminPage"));
const LeadsSharesPage = React.lazy(() => import("./Pages/LeadsSharesPage"));
const WalletPage = React.lazy(() => import("./Pages/WalletPage"));
const AffiliateCampaignsPage = React.lazy(() =>
  import("./Pages/AffiliateCampaignsPage")
);
const AffiliateProfilePage = React.lazy(() =>
  import("./Pages/AffiliateProfilePage")
);
const AffiliatesActivityPage = React.lazy(() =>
  import("./Pages/AffiliatesActivityPage")
);
const AffiliatesPage = React.lazy(() => import("./Pages/AffiliatesPage"));
const AffiliateWalletPage = React.lazy(() =>
  import("./Pages/AffiliateWalletPage")
);
const CreateCampaignPage = React.lazy(() =>
  import("./Pages/CreateCampaignPage")
);
const UnaffiliatePage = React.lazy(() => import("./Pages/UnaffiliatePage"));
const RepInvitedAffiliatesPage = React.lazy(() =>
  import("./Pages/RepInvitedAffiliatesPage")
);
const RepPage = React.lazy(() => import("./Pages/RepPage"));
const SingleCampaignPage = React.lazy(() =>
  import("./Pages/SingleCampaignPage")
);
const CompanyProfilePage = React.lazy(() =>
  import("./Pages/CompanyProfilePage")
);
const ProtectedRoute = ({ token, children }) => {
  if (!token) {
    return <Navigate to="/login" replace />;
  }

  return children;
};
function App() {
  const token = localStorage.getItem("token");
  const userInfo = localStorage.getItem("user");
  let userObj = {};
  if (userInfo) {
    userObj = JSON.parse(userInfo);
  }

  // const user = JSON.parse(userStorage);
  const loggedIn = !!token;
  console.log(userInfo);

  if (loggedIn) {
    document.title = `Brandaf - ${userObj.companyName}`;
  }
  return (
    <div className="App bg-lightGray">
      <Header token={token} />
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<div className="mt-[25%] flex h-full w-full items-center justify-center">
              <SpinnerDotted className="mt-4" color="#A590E3" enabled />
            </div>}>
              <ProtectedRoute token={token}>
                <OverviewPage token={token} />
              </ProtectedRoute>
            </Suspense>
          }
        />
        <Route
          path="wallet"
          element={
            <Suspense
              fallback={
                <div className="mt-[25%] flex h-full w-full items-center justify-center">
                  <SpinnerDotted className="mt-4" color="#A590E3" enabled />
                </div>
              }
            >
              <ProtectedRoute token={token}>
                <WalletPage token={token} />
              </ProtectedRoute>
            </Suspense>
          }
        />
        <Route
          path="load"
          element={
            <Suspense fallback={""}>
              <LoadScreen />
            </Suspense>
          }
        />

        <Route
          path="notifications"
          element={
            <Suspense
              fallback={
                <div className="mt-[25%] flex h-full w-full items-center justify-center">
                  <SpinnerDotted className="mt-4" color="#A590E3" enabled />
                </div>
              }
            >
              <ProtectedRoute token={token}>
                <NotificationsPage token={token} />
              </ProtectedRoute>
            </Suspense>
          }
        />

        <Route
          path="forgot-email"
          element={
            <Suspense fallback={""}>
              <ForgotEmail token={token} />
            </Suspense>
          }
        />

        <Route
          path="forgot-phone"
          element={
            <Suspense fallback={""}>
              <ForgotPhone token={token} />
            </Suspense>
          }
        />

        <Route
          path="reset-password/resetCode/:resetCode/userId/:userId"
          element={
            <Suspense fallback={""}>
              <ResetPassword token={token} />
            </Suspense>
          }
        />

        <Route
          path="test"
          element={
            <Suspense fallback={""}>
              <Test />
            </Suspense>
          }
        />

        <Route
          path="/sign-in"
          element={
            <Suspense fallback={""}>
              {loggedIn ? <Navigate to="/overview" /> : <SignUp />}
            </Suspense>
          }
        />

        <Route
          path="/login"
          element={
            <Suspense fallback={""}>
              {loggedIn ? <Navigate to="/overview" /> : <LoginPage />}
            </Suspense>
          }
        />

        <Route
          path="/affiliate-profile/:id"
          element={
            <Suspense
              fallback={
                <div className="mt-[25%] flex h-full w-full items-center justify-center">
                  <SpinnerDotted className="mt-4" color="#A590E3" enabled />
                </div>
              }
            >
              <ProtectedRoute token={token}>
                <AffiliateProfilePage token={token} />
              </ProtectedRoute>
            </Suspense>
          }
        />

        <Route
          path="/affiliate-wallet"
          element={
            <Suspense
              fallback={
                <div className="mt-[25%] flex h-full w-full items-center justify-center">
                  <SpinnerDotted className="mt-4" color="#A590E3" enabled />
                </div>
              }
            >
              <ProtectedRoute token={token}>
                <AffiliateWalletPage />
              </ProtectedRoute>
            </Suspense>
          }
        />

        <Route
          path="/single-campaign/:id"
          element={
            <Suspense
              fallback={
                <div className="mt-[25%] flex h-full w-full items-center justify-center">
                  <SpinnerDotted className="mt-4" color="#A590E3" enabled />
                </div>
              }
            >
              <ProtectedRoute token={token}>
                <SingleCampaignPage token={token} />
              </ProtectedRoute>
            </Suspense>
          }
        />

        <Route
          path="/edit-campaign/:id"
          element={
            <Suspense
              fallback={
                <div className="mt-[25%] flex h-full w-full items-center justify-center">
                  <SpinnerDotted className="mt-4" color="#A590E3" enabled />
                </div>
              }
            >
              <ProtectedRoute token={token}>
                <EditCampaignPage token={token} />
              </ProtectedRoute>
            </Suspense>
          }
        />

        <Route
          path="/affiliate-activity"
          element={
            <Suspense
              fallback={
                <div className="mt-[25%] flex h-full w-full items-center justify-center">
                  <SpinnerDotted className="mt-4" color="#A590E3" enabled />
                </div>
              }
            >
              <ProtectedRoute token={token}>
                <AffiliatesActivityPage token={token} />
              </ProtectedRoute>
            </Suspense>
          }
        />

        <Route
          path={"/company-profile"}
          element={
            <Suspense
              fallback={
                <div className="mt-[25%] flex h-full w-full items-center justify-center">
                  <SpinnerDotted className="mt-4" color="#A590E3" enabled />
                </div>
              }
            >
              <ProtectedRoute token={token}>
                <CompanyProfilePage token={token} />
              </ProtectedRoute>
            </Suspense>
          }
        />

        <Route
          path={"/rep-profile"}
          element={
            <Suspense
              fallback={
                <div className="mt-[25%] flex h-full w-full items-center justify-center">
                  <SpinnerDotted className="mt-4" color="#A590E3" enabled />
                </div>
              }
            >
              <ProtectedRoute token={token}>
                <RepPage token={token} />
              </ProtectedRoute>
            </Suspense>
          }
        />

        <Route
          path={"/rep-profile/:id"}
          element={
            <Suspense
              fallback={
                <div className="mt-[25%] flex h-full w-full items-center justify-center">
                  <SpinnerDotted className="mt-4" color="#A590E3" enabled />
                </div>
              }
            >
              <ProtectedRoute token={token}>
                <OtherRepPage token={token} />
              </ProtectedRoute>
            </Suspense>
          }
        />

        <Route
          path="/affiliate-campaigns/:id"
          element={
            <Suspense
              fallback={
                <div className="mt-[25%] flex h-full w-full items-center justify-center">
                  <SpinnerDotted className="mt-4" color="#A590E3" enabled />
                </div>
              }
            >
              <ProtectedRoute token={token}>
                <AffiliateCampaignsPage token={token} />
              </ProtectedRoute>
            </Suspense>
          }
        />

        <Route
          path="/affiliates"
          element={
            <Suspense
              fallback={
                <div className="mt-[25%] flex h-full w-full items-center justify-center">
                  <SpinnerDotted className="mt-4" color="#A590E3" enabled />
                </div>
              }
            >
              <ProtectedRoute token={token}>
                <AffiliatesPage />
              </ProtectedRoute>
            </Suspense>
          }
        />

        <Route
          path="/overview"
          element={
            <Suspense fallback={<div className="mt-[25%] flex h-full w-full items-center justify-center">
              <SpinnerDotted className="mt-4" color="#A590E3" enabled />
            </div>}>
              <ProtectedRoute token={token}>
                <OverviewPage token={token} />
              </ProtectedRoute>
            </Suspense>
          }
        />

        <Route
          path="/leads-shares"
          element={
            <Suspense
              fallback={
                <div className="mt-[25%] flex h-full w-full items-center justify-center">
                  <SpinnerDotted className="mt-4" color="#A590E3" enabled />
                </div>
              }
            >
              <ProtectedRoute token={token}>
                <LeadsSharesPage token={token} />
              </ProtectedRoute>
            </Suspense>
          }
        />

        <Route
          path="/brand-admin"
          element={
            <Suspense
              fallback={
                <div className="mt-[25%] flex h-full w-full items-center justify-center">
                  <SpinnerDotted className="mt-4" color="#A590E3" enabled />
                </div>
              }
            >
              <BrandAdminPage token={token} />
            </Suspense>
          }
        />

        <Route
          path="/rep-campaigns"
          element={
            <Suspense fallback={""}>
              <RepCampaignPage token={token} />
            </Suspense>
          }
        />

<Route
          path="/rep-campaigns/:id"
          element={
            <Suspense fallback={""}>
              <OtherRepCampaignPage token={token}/>
            </Suspense>
          }
        />
        <Route
          path="/rep-affiliates"
          element={
            <Suspense fallback={""}>
              <RepInvitedAffiliatesPage token={token} />
            </Suspense>
          }
        />
           <Route
          path="/rep-affiliates/:id"
          element={
            <Suspense fallback={""}>
              <OtherRepAffiliatesPage token={token} />
            </Suspense>
          }
        />
        <Route
          path="/create-campaign"
          element={
            <Suspense
              fallback={
                <div className="mt-[25%] flex h-full w-full items-center justify-center">
                  <SpinnerDotted className="mt-4" color="#A590E3" enabled />
                </div>
              }
            >
              <CreateCampaignPage token={token} />
            </Suspense>
          }
        />
        <Route
          path="/unaffiliate-page"
          element={
            <Suspense fallback={""}>
              <UnaffiliatePage />
            </Suspense>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
