import React from "react";

export default function SignUpTextArea({
  label,
  id,
  maxLength,
  value,
  onBlur,
  onChange,
  error,
}) {
  return (
    <div className="relative my-8 flex flex-col">
      <textarea
        id={id}
        className="mt-2 resize-none min-h-[240px] scrollbar-hide rounded-md border-none shadow-[inset_-4px_-4px_4px_#FFFFFF,inset_4px_4px_4px_rgba(0,0,0,0.05)]"
        maxLength={maxLength}
        value={value}
        onChange={onChange}
        name={id}
        onBlur={onBlur}
      ></textarea>
      <label
        for={id}
        className="col-form-label pointer-events-none absolute left-4 -top-2 mt-2 bg-bafWhite text-xs transition-all peer-focus:-top-8 peer-focus:text-sm peer-focus:text-black"
      >
        {label}
      </label>
      <p className="ErrorMessage ml-1 mt-2 text-[10px] italic text-errorRed">
        {error}
      </p>
    </div>
  );
}
