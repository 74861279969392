import axios from "axios";
import { useFormik } from "formik";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "../Shared/Container";
import HollowButton from "../Shared/HollowButton";
import SignUpInput from "../Shared/SignUpInput";
import * as Yup from "yup";
import spaceship from "../images/starships.gif";
import AuthBackground from "../Shared/AuthBackground";

export default function ForgotPhone() {
  const [error, setError] = useState("");
  const [page, setPage] = useState(1);
  // const [code, setCode] = useState("");
  let navigate = useNavigate();

  useEffect(() => {
    document.title = "Brandaf - Brand Login";
  }, []);
  const forgotPassword =
    `${process.env.REACT_APP_PROD_URL}forgotPasswordNew`;

  const validateCode =
    `${process.env.REACT_APP_PROD_URL}validateResetCode`;

  const useSSNFields = () => {
    const [ssnValues, setValue] = React.useState({
      ssn1: "",
      ssn2: "",
      ssn3: "",
      ssn4: "",
      ssn5: "",
      ssn6: "",
    });
    const resetCode = Object.values(ssnValues);
    const actualCode = resetCode.join("");

    return {
      handleChange: (e) => {
        const { maxLength, value, name } = e.target;
        const [fieldName, fieldIndex] = name.split("-");

        // Check if they hit the max character length
        if (value.length >= maxLength) {
          // Check if it's not the last input field
          if (parseInt(fieldIndex, 10) < 6) {
            // Get the next input field
            const nextSibling = document.querySelector(
              `input[name=ssn-${parseInt(fieldIndex, 10) + 1}]`
            );

            // If found, focus the next field
            if (nextSibling !== null) {
              nextSibling.focus();
            }
          }
        }
        console.log(fieldName);
        useSSNFields.resetCode = actualCode;

        setValue({
          ...ssnValues,
          [`ssn${fieldIndex}`]: value,
        });
      },
      resetCode: actualCode,
    };
  };

  const handlePageChange = (path) => {
    navigate(path);
  };

  const formik = useFormik({
    initialValues: {
      phone: "",
    },
    validationSchema: Yup.object({
      phone: Yup.string().required("Please enter your phone number"),
    }),
    onSubmit: (values, { resetForm }) => {
      const code = localStorage.getItem("code");
      if (page === 1) {
        axios
          .post(
            forgotPassword,
            { phoneNumber: formik.values.phone },
            {
              headers: { "Access-Control-Allow-Origin": "*" },
            }
          )
          .then((res) => {
            console.log(res.data);
            if (res.data.responseCode === 0) {
              setPage((page) => page + 1);
            } else {
              setError(res.data.errorMsg);
              setTimeout(() => {
                setError("");
              }, 5000);
            }
          });
      } else {
        axios
          .post(
            validateCode,
            {
              phoneNumber: formik.values.phone,
              resetCode: code,
            },
            {
              headers: { "Access-Control-Allow-Origin": "*" },
            }
          )
          .then((res) => {
            console.log(res);
            if (res.data.responseCode === 0) {
              handlePageChange(
                `/reset-password/resetCode/${code}/userId/${res.data.response.userId}`
              );
            } else {
              setError(res.data.errorMsg);
            }
          });
      }
    },
  });

  const resendCode = () => {
    axios
      .post(
        forgotPassword,
        { phoneNumber: formik.values.phone },
        {
          headers: { "Access-Control-Allow-Origin": "*" },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.responseCode === 0) {
          setPage((page) => page + 1);
        } else {
          setError(res.data.errorMsg);
          setTimeout(() => {
            setError("");
          }, 5000);
        }
      });
  };

  //* Disable Values
  const disableNextButton = !formik.values.phone;

  // const nextPage = () => {
  //   setPage((prevPage) => (prevPage += 1));
  //   window.scrollTo(0, 0);
  // };
  // const prevPage = () => {
  //   setPage((prevPage) => (prevPage -= 1));
  //   window.scrollTo(0, 0);
  // };
  const handleEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const SSNField = () => {
    const { handleChange, resetCode } = useSSNFields();
    if (resetCode.length === 6) {
      localStorage.setItem("code", resetCode);
    }
    return (
      <>
        <input
          className={`h-12 w-10 rounded-md border text-center ${
            error ? "border-red" : "border-bafBlack"
          }`}
          name="ssn-1"
          maxLength={1}
          onChange={handleChange}
          onPaste={handleChange}
        />
        <input
          className={`h-12 w-10 rounded-md border text-center ${
            error ? "border-red" : "border-bafBlack"
          }`}
          name="ssn-2"
          maxLength={1}
          onChange={handleChange}
        />
        <input
          className={`h-12 w-10 rounded-md border text-center ${
            error ? "border-red" : "border-bafBlack"
          }`}
          name="ssn-3"
          maxLength={1}
          onChange={handleChange}
        />

        <input
          className={`h-12 w-10 rounded-md border text-center ${
            error ? "border-red" : "border-bafBlack"
          }`}
          type="number"
          name="ssn-4"
          maxLength={1}
          onChange={handleChange}
        />
        <input
          className={`h-12 w-10 rounded-md border text-center ${
            error ? "border-red" : "border-bafBlack"
          }`}
          type="number"
          name="ssn-5"
          maxLength={1}
          onChange={handleChange}
        />
        <input
          className={`h-12 w-10 rounded-md border text-center ${
            error ? "border-red" : "border-bafBlack"
          }`}
          type="number"
          name="ssn-6"
          maxLength={1}
          onChange={handleChange}
          onKeyDown={(e) => handleEnter(e)}
        />
      </>
    );
  };

  return (
    <div className=" flex h-screen flex-col  bg-[url('/src/images/stars.gif')]  bg-bafBlack md:flex-row">
      <AuthBackground />
      {page === 1 && (
        <form
          className={
            "flex min-h-[470px] mt-8   rounded-md  bg-bafWhite sm:mt-8 sm:min-h-[600px] md:h-screen md:mt-0  md:w-1/2 lg:w-1/2"
          }
          onSubmit={formik.handleSubmit}
        >
          <Container>
            <div className="flex md:h-full items-center md:px-12">
              <div className=" h-[50%]  md:min-w-[350px] lg:min-w-[400px] max-w-[500px] max-h-[500px]">
                <h1 className="text-3xl font-semibold">Enter Phone Number</h1>
                <p className="mt-4 text-xs sm:text-sm">
                  This process will only recover your e-mail. Once you recover
                  your e-mail, you can enter a new password.
                  {/* <Link
                to="/sign-in"
                className="ml-1 shadow-[inset_0_-0.4em_#BFFFBC]"
              >
                Sign Up
              </Link>{" "}
              here. */}
                </p>
                <fieldset className="mt-8 flex flex-col justify-between ">
                  <SignUpInput
                    title="Phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    id="phone"
                    error={
                      (formik.touched.phone && formik.errors.phone) || error
                    }
                  />
                </fieldset>
                {/* <Link
              to="/forgot-phone"
              className="ml-4 text-xs text-darkPurple"
            >
              Forgot your e-mail?
            </Link> */}
                {/* <p className="text-sm text-errorRed">{error}</p> */}
                <footer className="flex justify-between mt-20 md:mt-32">
                  <Link to="/forgot-email">
                    <HollowButton title="Back" medium />
                  </Link>
                  <HollowButton
                    title="Next"
                    medium
                    disabled={disableNextButton}
                  />
                </footer>
              </div>
            </div>
          </Container>
        </form>
      )}
      {/* Third Page */}
      {page > 1 && (
        <form
          className="flex min-h-[470px] w-full flex-col justify-evenly md:px-12 sm:px-8 px-2 rounded-md bg-bafWhite py-8 mt-8  sm:min-h-[600px] md:h-[100vh] md:mt-0 md:w-[50%] md:min-w-[450px]"
          onSubmit={formik.handleSubmit}
        >
          <div className=" h-[50%] min-h-[350px]  md:min-w-[350px] lg:min-w-[400px] max-w-[500px] max-h-[500px]">
            <h1 className=" text-3xl font-semibold">Enter Reset Code</h1>
            <p className="mt-2 text-xs sm:text-sm">
              The code has been sent to your phone!
            </p>
            <p className="text-xs sm:text-sm">It will expire in 10 minutes.</p>
            <fieldset className="my-4 flex min-w-[300px] max-w-[400px] justify-between">
              <SSNField />
            </fieldset>
            <p className="ml-4 text-sm text-errorRed">{error}</p>
            <p className="mt-2 sm:mt-4 text-xs sm:text-sm">
              Didn’t get the code?{" "}
              <span
                onClick={resendCode}
                className=" font-bold shadow-[inset_0_-0.4em_#BFFFBC] hover:cursor-pointer"
              >
                Resend now.
              </span>
            </p>
            <div className="flex w-full justify-between  mt-20">
              <HollowButton
                title="Back"
                medium
                handleClick={() => setPage(1)}
              />

              <HollowButton title="Enter" medium />
            </div>
          </div>
        </form>
      )}
    </div>
  );
}
