import React from 'react'
import spaceship from '../images/starships.gif'

export default function AuthBackground() {
  return (
    <section
      className={`lg:w-1/2" } bg-[url('/src/images/stars.gif')] flex-col items-center justify-center px-4 md:flex md:w-1/2
      xl:p-24`}
    >
      <div className="   justify-center md:flex">
        <div className="">
          <img
            src={spaceship}
            alt="spaceship"
            className="mx-auto hidden h-[350px] md:block"
          />
          <h1 className="lg:text-[6rem]] text-6xl font-bold tracking-normal text-bafWhite  md:text-[5rem] ">
            brand<span className="text-darkPurple ">af</span>
          </h1>
          <h2 className="text-3xl font-bold text-[#A590E3]  ">
            Brand Portal
          </h2>
        </div>
      </div>
    </section>
  )
}
