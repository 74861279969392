import React from "react";
import { useState, useEffect } from "react";

import { useFormik } from "formik";
import SignUpInput from "../Shared/SignUpInput";
import Container from "../Shared/Container";
import HollowButton from "../Shared/HollowButton";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import * as Yup from "yup";
import AuthBackground from "../Shared/AuthBackground";

export default function ResetPassword({ token }) {
  const { userId, resetCode } = useParams(); // Unpacking and retrieve id
  console.log(userId, resetCode);
  const [error, setError] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [success, setSuccess] = useState("");
  const [page, setPage] = useState(1);

  useEffect(() => {
    document.title = "Brandaf - Brand Reset Password";
  }, []);
  const resetPassword = `${process.env.REACT_APP_PROD_URL}resetPassword`;

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Password is required")
        .minLowercase(1, "Password must contain one lowercase letter")
        .minUppercase(1, "Password must contain one uppercase letter")
        .minSymbols(1, "Password must contain one special character")
        .minNumbers(1, "Password must contain at least 1 number")
        .min(8, "Password must have 8 or more characters"),
      confirmPassword: Yup.string().required("Field is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      const userInfo = {
        userId,
        resetCode,
        newPassword: values.password,
      };
      console.log(userInfo);
      axios
        .post(resetPassword, userInfo, {
          headers: { "Access-Control-Allow-Origin": "*" },
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.responseCode === 0) {
            setSuccess(res.data.successMsg);
            setPage((page) => page + 1);
          } else {
            setError(res.data.errorMsg);
            console.log(res);
            setTimeout(() => {
              setError("");
            }, 3000);
          }
        });
    },
  });
  return (
    <div className=" min-h-screen bg-bafBlack  bg-cover bg-fixed [background-position:30px] md:flex md:[background-position:40%] lg:[background-position:50%] xl:bg-auto">
      <AuthBackground />
      {page === 1 && (
        <form
          className={
            "flex  min-h-[470px] items-center  rounded-md bg-bafWhite mt-8 md:mt-0 md:w-1/2 md:px-8 lg:w-1/2"
          }
          onSubmit={formik.handleSubmit}
        >
          <Container>
            <div className="md:mx-8 lg:mx-16">
              <h1 className="mt-4 text-3xl font-semibold">Reset Password</h1>
              {/* <p className="mt-4">
              Don't have an account?
              <Link
                to="/sign-in"
                className="ml-1 shadow-[inset_0_-0.4em_#BFFFBC]"
              >
                Sign Up
              </Link>{" "}
              here.
            </p> */}
              <fieldset className=" flex flex-col justify-between ">
                <SignUpInput
                  type="password"
                  title="Password"
                  id="password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  error={formik.touched.password && formik.errors.password}
                />
                <SignUpInput
                  type="password"
                  title="Re-Type Password"
                  id="confirmPassword"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  error={
                    formik.values.password !== formik.values.confirmPassword &&
                    "Passwords don't match"
                  }
                />
                <p className="text-sm text-errorRed">{error}</p>
              </fieldset>
              <p className="py-2 text-sm">Password must have the following:</p>
              <ul className="list-disc">
                <li className="ml-8 text-xs tracking-wider">
                  length of 8 or more characters
                </li>
                <li className="ml-8 text-xs tracking-wider">
                  one lowercase letter
                </li>
                <li className="ml-8 text-xs tracking-wider">
                  one uppercase letter
                </li>
                <li className="ml-8 text-xs tracking-wider">one numerical</li>
                <li className="ml-8 mb-4 text-xs tracking-wider sm:mb-8">
                  one special character
                </li>
              </ul>
              <Link to="/login" className="mt-4 ml-2 text-xs text-darkPurple">
                Login as another user.
              </Link>
              {/* <Link to="/forgot-email" className="text-xs text-darkPurple ml-4">Lost your credentials?</Link> */}
              <footer className="mt-8 flex justify-end sm:mt-12 md:mt-16">
                <HollowButton title="Update Password" type="submit" medium />
              </footer>
            </div>
          </Container>
        </form>
      )}

      {/* Third Page */}
      {page === 2 && (
        <section className=" flex min-h-[470px] w-full flex-col justify-between rounded-md bg-bafWhite py-8 px-6 sm:mt-8 sm:min-h-[600px]  md:h-[100vh] md:w-[50%] md:min-w-[400px]">
          <Container>
            <div className="flex flex-col items-center max-w-[400px] mx-auto justify-between sm:mt-[20%] md:mt-[30%] lg:mt-[25%] xl:mt-[20%] sm:min-h-[400px] ">
              <div>
                <h1 className=" text-3xl lg:text-4xl font-bold ">Password Updated!</h1>
                <p className="mx-auto mt-4 text-xs">
                  Your password has been updated successfully.
                </p>
              </div>
              <div className="mt-8 flex w-full justify-end md:px-10">
                <Link to="/login">
                  <HollowButton title="Log in" medium />
                </Link>
              </div>
            </div>
          </Container>
        </section>
      )}
    </div>
  );
}
