import React from "react";
import DownArrow from "../images/small-down-arrow.svg";
import KebabMenuButton from "../Shared/KebabMenuButton";
import { IoIosArrowDown } from "react-icons/io";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";

export default function AffiliateEarningCard({
  affiliate,
  setDisableAffiliateRefresh,
  token,
}) {
  const {
    affiliateId,
    campaignsShared,
    email,
    firstName,
    lastName,
    phone,
    totalEarnings,
    userImage,
    tierName,
    tierImage,
    totalPoints,
    tierEndPoint,
  } = affiliate;

  const percentage = (totalPoints / tierEndPoint) * 100;

  const dollarSign = String.fromCodePoint(0x00024);
  const shortenedCampaignsShared =
    campaignsShared &&
    Array.isArray(campaignsShared) &&
    campaignsShared.slice(0, 2);

  return (
    <div className="AffiliateEarningCard h-[323px] w-[290px] rounded-md py-4 pl-8 bg-bafWhite relative">
      <div className="absolute top-3 right-4">
        <KebabMenuButton
          token={token}
          affiliateOption
          affiliateID={affiliateId}
          setDeleteRefresh={setDisableAffiliateRefresh}
        />
      </div>
      <header className="flex items-center w-[220px]">
        <div className="relative h-12 w-12 mr-2">
          <img
            src={userImage}
            alt="avatar"
            className="max-w-none w-12 h-12 rounded-[50%] absolute z-[1]"
          />
          <img
            src={tierImage}
            alt="tier icon"
            className="absolute z-[2] w-[40%] -right-2 bottom-0"
          />
          <div className="absolute h-12 w-12 scale-[1.1] ">
            <CircularProgressbar
              circleRatio={0.85}
              value={percentage}
              styles={buildStyles({
                pathColor: "#16DB93",
                trailColor: "#E5E5E5",
                rotation: 1 / 2 + 1 / 14,
              })}
            />
          </div>
        </div>
        <div className="ml-2">
          <h2 className="text-sm font-bold line-clamp-1">{`${firstName} ${lastName}`}</h2>
          <p className="text-xs mt-1 text-darkPurple">{tierName}</p>
        </div>
      </header>

      <div className="mt-6">
        <p className="text-xs text-bafGrey">Amount Earned</p>
        <p className="text-lg font-bold">
          {`
          ${dollarSign}
          ${totalEarnings ? totalEarnings : 0}`}
        </p>
      </div>

      <div className="flex justify-between mt-4 w-[230px]">
        <p className="text-xs text-bafGrey">Campaigns Shared</p>
        <p className="text-xs text-bafGrey">Shares</p>
      </div>

      <div className="flex flex-col  h-[144px]">
        <div>
          {shortenedCampaignsShared?.length > 0 ? (
            shortenedCampaignsShared.map((campaign) => {
              return (
                <div className="flex justify-between mt-4">
                  <p className="truncate text-sm max-w-[170px]">
                    {campaign.campaignName}
                  </p>
                  <p className="text-sm font-bold mr-7">
                    {campaign.totalShares}
                  </p>
                </div>
              );
            })
          ) : (
            <p className="text-sm mt-4">No Campaigns Shared</p>
          )}
        </div>
        {/* {shortenedCampaignsShared?.length === 0 ? (
          <div className="flex justify-between mt-4">
            <p className="text-sm min-h-[56px]"> </p>
          </div>
        ) : shortenedCampaignsShared?.length === 1 ? (
          <div className="flex justify-between mt-4">
            <p className="text-sm min-h-[20px]"> </p>
          </div>
        ) : null} */}
        <footer className="flex mt-auto items-center">
          <a
            href={`/affiliate-profile/${affiliateId}`}
            className="relative text-[14px] text-darkPurple mr-4"
          >
            View More
            <IoIosArrowDown className="text-lg text-darkPurple font-black absolute top-1/2 -translate-y-1/2 inline ml-4" />
          </a>
        </footer>
      </div>
    </div>
  );
}
