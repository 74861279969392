import React from "react";
import { Link } from "react-router-dom";

export default function Menu({ title, to, external, highlight, bold }) {
  return external ? (
    <li className="LinkHighlight mx-2 max-w-fit min-w-fit list-none py-1">
      <a
        target="_blank"
        rel="noreferrer"
        href={to}
        className="group hidden min-w-fit max-w-fit text-xs font-semibold transition duration-300 md:inline md:text-sm lg:text-base lg:font-bold lg:text-sm"
      >
        {title}
        <span className="block h-0.5 max-w-0 bg-darkPurple transition-all duration-500 group-hover:max-w-full"></span>
      </a>
    </li>
  ) : (
    <div className="hover:border-darkPurple-600  w-full">
      <li className="LinkHighlight w-full h-full min-w-fit list-none ">
        <Link
          to={to}
          className={`pl-1 block group w-full min-h-[40px] h-full text-xs py-[14px] md:text-sm  min-w-fit ${
            bold && "font-bold"
          } transition duration-300 lg:text-base `}
        >
          {title}
        </Link>
      </li>
    </div>
  );
}
