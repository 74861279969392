import { useState, useRef } from "react";
import HollowButton from "./HollowButton";
import Upload from "../images/upload-icon-white.svg";
import { ReactComponent as CloseIcon } from "../images/close-button.svg";

function DragDropFile({
  handleFile,
  handleRemoveFile,
  preview,
  title,
  originalImage,
  fileName,
  upload,
  additional,
  acceptedFileType,
  buttonTitle,
  allowClick,
  error,
  modalCrm,
  inputValue,
}) {
  // drag state
  const [dragActive, setDragActive] = useState(false);
  // ref
  const inputRef = useRef(null);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files[0]);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files[0]);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = (e) => {
    e.preventDefault();
    inputRef.current.click();
  };

  // const handleClick = (e) => {
  //   if (allowClick) {
  //     e.preventDefault()
  //   }
  // }
  return (
    <>
      <form
        className={`relative mt-2  max-w-full text-center ${
          modalCrm ? "h-auto lg:h-[8rem]" : "h-[16rem] lg:h-[20rem]"
        }`}
        onDragEnter={handleDrag}
        onSubmit={(e) => e.preventDefault()}
      >
        <input
          ref={inputRef}
          type="file"
          id="input-file-upload"
          multiple={false}
          className="hidden disabled"
          onChange={handleChange}
          accept={acceptedFileType}
          value={inputValue}
          // onClick={e => e.preventDefault()}
        />
        <label
          id="label-file-upload"
          htmlFor="input-file-upload"
          onClick={allowClick ? "" : (e) => e.preventDefault()}
          className={`flex h-full items-center ${
            modalCrm ? "justify-left" : "justify-center"
          } ${!upload && "rounded-md border-2 border-dashed border-bafBlack"} ${
            dragActive ? "bg-lightPurple" : ""
          }`}
        >
          <div className={`${modalCrm ? "w-[100%]" : ""}`}>
            {preview ? (
              <img
                src={preview}
                className="h-[120px] w-[120px] mx-auto"
                alt="preview"
              />
            ) : (
              originalImage && (
                <img
                  src={originalImage}
                  className="mx-auto h-[130px] w-[130px]"
                  alt="preview"
                />
              )
            )}
            <p
              className={`max-w-[170px] mx-auto text-xs mt-2 ${
                dragActive ? "text-darkPurple" : "text-bafGrey"
              }`}
            >
              {title}
            </p>

            {!upload ? (
              <HollowButton
                backgroundHover
                handleClick={onButtonClick}
                title={buttonTitle || "Upload Image"}
                background="bafBlack"
                color="bafWhite"
                border="none"
                className="mt-3 w-[145px]"
              />
            ) : (
              <div
                className={`drag-drop-box flex ${
                  modalCrm ? "w-[100%]" : "w-[350px]"
                } flex-col items-center rounded-xl border-2 border-bafWhite ${
                  modalCrm ? "py-1" : "py-14"
                } text-bafWhite md:flex-row md:justify-center md:py-8`}
              >
                <img src={Upload} alt="Upload" />
                <p className="mx-10 text-center md:mx-4">
                  <span className="text-darkPurple">Drag and drop</span> or{" "}
                  <span className="text-darkTeal">browse</span> files
                </p>
              </div>
            )}
          </div>
        </label>
        {dragActive && (
          <div
            id="drag-file-element"
            className="absolute top-0 right-0 bottom-0 left-0 h-full w-full rounded-xl"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
      </form>
      <div>
        {fileName && (
          <p className="mt-4 text-darkPurple text-left flex items-center">
            {fileName}
            <CloseIcon
              onClick={handleRemoveFile}
              className="fill-bafWhite w-6 h-6 ml-2 cursor-pointer"
            />
          </p>
        )}
        {error && <p className="mt-4 text-errorRed text-left">{error}</p>}
      </div>
    </>
  );
}
export default DragDropFile;
