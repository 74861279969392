import React from "react";

export default function WalletCard({ title, amount, currency }) {
  return (
    <div
      className={`WalletCard Card flex flex-col justify-evenly w-full max-w-[210px] md:h-[165px] bg-bafWhite p-4 border-b-2  
      ${title === "Paid to Baf" && "border-b-darkPurple"} 
      ${title === "Pending Payouts" && "border-b-brightYellow"} 
      ${title === "Paid to Affiliates" && "border-b-peach"} 
      ${title === "Balance" && "border-b-darkTeal"}`}
    >
      <h5 className=" text-sm ">{title}</h5>
      <p className="font-semibold text-xl my-4 truncate">${amount || 0}</p>
      <p className="text-sm text-bafGrey">USD</p>
    </div>
  );
}
