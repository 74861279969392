import React, { useState } from "react";
import HollowButton from "../Shared/HollowButton";
import { ReactComponent as CloseIcon } from "../images/close-button2.svg";

const PrivacyAgreementModal = ({
  showModal,
  handleModal,
  showCheck,
  handleCheck,
}) => {
  const toggleModal = () => {
    handleModal();
  };
  const check = () => {
    handleCheck();
    handleModal();
  };
  return (
    <>
      {showModal ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center  bg-bafGrey bg-opacity-30 outline-none focus:outline-none backdrop-brightness-[0.2] backdrop-blur-[2px]">
            <div className="relative my-6 mx-auto h-[70%] w-[70%] min-w-[350px] max-w-[750px] overflow-y-hidden rounded-2xl bg-bafBlack">
              <div className=" relative flex w-full flex-col rounded-lg border-0 shadow-lg outline-none focus:outline-none">
                <div className="flex flex-col justify-center p-5">
                  <h3 className="text-center text-lg font-semibold text-bafWhite sm:text-3xl">
                    Privacy Agreements
                  </h3>

                  <button
                    className="absolute right-5"
                    onClick={() => toggleModal()}
                  >
                    <CloseIcon className="w-6 h-6" />
                  </button>
                </div>
              </div>
              <div className="modal-bg flex h-full flex-wrap overflow-y-scroll p-8">
                <div>
                  <h5 className="mb-4 text-lg font-bold">
                    WEBSITE & BUSINESS-TO-BUSINESS PRIVACY NOTICE
                  </h5>
                  <h5 className="mb-4 text-lg font-bold">Introduction</h5>

                  <p className="mb-4">
                    Welcome to the Brand Affiliate Privacy Notice (“{" "}
                    <span className="font-bold">Privacy Notice</span>”). Brand
                    Affiliate LLC (“
                    <span className="font-bold">
                      {" "}
                      Brand Affiliate,” “BAF,” “we,” “us,” or “our
                    </span>
                    ”) is an affiliate marketing company. We’ve created this
                    resource to provide you with valuable transparency and
                    choice on how we might collect, use, and share your personal
                    information.
                  </p>

                  <h5 className="mb-4 text-lg font-bold">
                    Scope of this Privacy Notice
                  </h5>
                  <p className="mb-4">
                    This Privacy Notice is intended to describe how BAF collects
                    and uses personal information of our clients, prospective
                    clients, business-to-business contacts, and other visitors
                    to this website and any other website on which this Privacy
                    Notice is posted (“{" "}
                    <span className="font-bold"> Website or Websites</span>”).
                  </p>

                  <p className="mb-4">
                    If you are looking for information on how BAF collects and
                    uses consumer personal information in connection with our
                    services, please click here to view our Services Privacy
                    Notice.
                  </p>

                  <p className="mb-4">
                    We may us several capitalized terms throughout this Privacy
                    Notice that may be unfamiliar to you. You can find the
                    definitions to these terms at the bottom of this page, or by
                    clicking here.
                  </p>

                  <h5 className="mb-4 text-lg font-bold">
                    I. INFORMATION COLLECTION & USE
                  </h5>
                  <p className="mb-4">
                    We will sometimes give you the opportunity to provide
                    information directly to us through our corporate Websites –
                    such as if you are a business interested in a relationship
                    with us or an individual completing one of our surveys.
                    Additionally, as you interact with our Websites, we may use
                    (or engage with business partners and vendors that use)
                    automatic data collection technologies such as cookies
                    (described below) to collect certain information about your
                    browser or device, as well as activities or actions taken on
                    the websites.
                  </p>

                  <h5 className="mb-4 text-lg font-bold">
                    1. Personal Information Collected by BAF
                  </h5>

                  <p className="mb-4">
                    Depending on how you interact with us, we may collect the
                    following personal information:
                  </p>

                  <p className="mb-4">
                    <span className="mb-4 font-bold">Contact Information</span>{" "}
                    such as your name, email address, telephone number, the
                    company you work for, business title, postal address, and
                    other similar types of information. This information is
                    collected directly from you when:
                  </p>

                  <ul className="mb-4">
                    <li className="mb-4">
                      you attend or register for our marketing events;
                    </li>
                    <li className="mb-4">
                      you use the functionalities and features of our services,
                      such as our affiliate platform;
                    </li>
                    <li className="mb-4">
                      you correspond with us directly, such as when you fill out
                      a form, send us an email, or sign up for our services;
                    </li>
                    <li className="mb-4">
                      you choose to disclose your personal information on
                      message boards, chats, profile pages, social media posts
                      or comments, blogs, and other features on our Website or
                      our services with such capability;
                    </li>
                    <li className="mb-4">
                      we may also collect your personal information from
                      publicly available sources, such as LinkedIn, or receive
                      it from our partners who have collected it therefrom.
                    </li>
                  </ul>

                  <p className="mb-4">
                    {" "}
                    <span className="font-bold">
                      {" "}
                      Browser and device information{" "}
                    </span>
                    such as the type of web browser you are using and its
                    version, your IP address, and what parts of our Websites you
                    have visited. This information is automatically collected
                    via Reporting Technologies (such as cookies, log files,
                    pixels, tags, or other similar technologies) when you visit
                    our Websites or open emails from us. Such technologies may
                    be operated by a third party or service provider acting on
                    our behalf. Some of these third parties may collect data
                    about your browsing behavior on our Websites and combine it
                    with data collected from other websites over time for
                    purposes that may include online interest-based advertising.
                    Most of these companies offer a way to opt-out of such use
                    of your information. You can learn more about how to opt-out
                    of this use in the Your Rights section below.
                  </p>

                  <p className="mb-4">
                    <em>
                      Note, however, that BAF will not directly (via our
                      brandaf.com domain) collect, retain, or share any data
                      regarding a particular user or device (including IP
                      addresses and user identifiers) on sites or apps not owned
                      by BAF. Any information collected on such sites or apps
                      will be collected via Reporting Technologies operated by
                      third parties, service providers, or other affiliated
                      companies. BAF’s tracking of users across websites in
                      connection with its services will be conducted via
                      alternate domains and Reporting Technologies.{" "}
                    </em>
                  </p>

                  <p className="mb-4">
                    {" "}
                    <b>Financial Information </b>such as credit card numbers,
                    billing address and/or other related information required
                    for payments made to and from BAF as part of our services.
                    This information is voluntarily provided by you, and is
                    stored in a password protected environment, and is used for
                    its intended purposes including providing our services,
                    billing, and accounts payable.
                  </p>

                  <h5 className="mb-4 text-lg font-bold">
                    2. BAF’s Purposes for Collecting Personal Information{" "}
                  </h5>
                  <p className="mb-4">
                    We may use your personal information for the following
                    purposes:
                  </p>

                  <ul className="mb-4">
                    <li className="mb-4">
                      Direct marketing in accordance with your preferences via
                      email, phone or other channels, including features of our
                      services. Such marketing could include newsletters, white
                      papers, market research opportunities and similar.
                    </li>
                    <li className="mb-4">
                      To fulfil requests you have made to us, including to
                      communicate with you, respond to your inquiries, and
                      provide updates on our products and services
                    </li>
                    <li className="mb-4">
                      {" "}
                      To analyze and report on trends and activities on our
                      Websites and our marketing efforts, such as understanding
                      the parts of our Websites visited most often
                    </li>
                    <li className="mb-4">
                      {" "}
                      To administer or improve our services, Websites and
                      marketing communications
                    </li>
                  </ul>

                  <h5 className="mb-4 text-lg font-bold">
                    II. INFORMATION SHARING BY BAF
                  </h5>

                  <p className="mb-4">
                    {" "}
                    <b>Sharing with BAF’s Partners and Affiliates:</b> We may
                    share your personal information with companies affiliated
                    with BAF under Publicis Groupe for the purposes described in
                    this Privacy Notice
                  </p>

                  <p className="mb-4">
                    {" "}
                    <b> Sharing with Clients:</b> We may share your personal
                    information with third party participants of our services,
                    such as creators and advertisers, as is necessary to provide
                    our services to you
                  </p>

                  <p className="mb-4">
                    {" "}
                    <b> Sharing with Service Providers:</b> We may share your
                    personal information with service providers to assist or
                    facilitate in the services we provide to our clients,
                    including payment processing, such as vendors,
                    administrative service providers, technology providers, and
                    carefully selected partners for services including, but not
                    limited to, data validation, enhancement, information
                    verification and suppression services.
                  </p>

                  <p className="mb-4">
                    {" "}
                    <b> Sharing with Third Parties:</b> We may share your
                    personal information with third parties including
                    advertisers, promotional partners, sponsors, and creators,
                    for the purposes stated in this Privacy Notice.
                  </p>

                  <p className="mb-4">
                    {" "}
                    <b>Sharing for Legal Purposes:</b> In addition, we may share
                    personal information with third parties in order to: (a)
                    comply with legal process or a regulatory investigation
                    (e.g. a subpoena or court order); (b) enforce our Terms of
                    Service, this Privacy Notice, or other contracts with you,
                    including investigation of potential violations thereof;
                    and/or (c) respond to claims that any content violates the
                    rights of third parties.
                  </p>

                  <p className="mb-4">
                    {" "}
                    <b> Sharing for Fraud Prevention & Safety:</b> We may share
                    your personal information with third parties or service
                    providers to protect the rights, property or personal safety
                    of us, our platform, our customers, our agents and
                    affiliates, its users and/or the public. We likewise may
                    provide information to other companies and organizations
                    (including law enforcement) for fraud protection, and
                    spam/malware prevention, and similar purposes.
                  </p>

                  <p className="mb-4">
                    {" "}
                    <b>Sharing In Event of a Corporate Transaction:</b> We may
                    also share personal information in the event of a major
                    corporate transaction, including for example a merger,
                    investment, acquisition, reorganization, consolidation,
                    bankruptcy, liquidation, or sale of some or all of our
                    assets, or for purposes of due diligence connected with any
                    such transaction.
                  </p>

                  <p className="mb-4">
                    {" "}
                    <b> Sharing with your Consent:</b> We may also share your
                    personal information for any other purpose with your consent
                  </p>

                  <h5 className="mb-4 text-lg font-bold">III. YOUR RIGHTS</h5>

                  <p className="mb-4">
                    <b>1. Marketing databases.</b>
                  </p>

                  <p className="mb-4">
                    If we market to you by email, in our corporate capacity –
                    such as if you are a client or prospective client and we
                    send information about our services – you may “unsubscribe”
                    from our marketing emails through a link placed in your
                    emails.
                  </p>

                  <p className="mb-4">
                    <b>2. Interest-Based Advertising.</b>
                  </p>

                  <p className="mb-4">
                    You may visit{" "}
                    <a
                      href="https://youradchoices.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      www.AboutAds.info
                    </a>{" "}
                    or the{" "}
                    <a
                      href="https://thenai.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Network Advertising Initiative
                    </a>{" "}
                    to learn more about interest-based advertising and to see
                    your opt-out choices from other participating companies that
                    may be collecting and using data on our Websites.
                  </p>

                  <p className="mb-4">
                    For those in Canada, you can opt-out via the DAAC’s industry
                    site at{" "}
                    <a
                      href="http://youradchoices.ca/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      http://youradchoices.ca/
                    </a>
                    .
                  </p>

                  <h5 className="mb-4 text-lg font-bold">
                    IV. LINKS TO THIRD PARTY SITES
                  </h5>

                  <p className="mb-4">
                    Our Websites may contain links to unaffiliated third-party
                    sites. We do not endorse or make any representations about
                    any third-party websites (or any information, software or
                    other products or materials found there) to which our sites
                    might link. This Privacy Notice does not apply to the data
                    collection and use practices of third-party websites. We
                    encourage you to review the privacy notices of these
                    unaffiliated third-party websites to understand their data
                    collection and use practices.
                  </p>

                  <h5 className="mb-4 text-lg font-bold">
                    V. PARTICIPATION IN ONLINE FORUMS
                  </h5>
                  <p className="mb-4">
                    Our Websites may provide you with the opportunity to
                    participate in various online forums. If you contribute any
                    content to an online forum, you retain your rights to any
                    content you post. However, by posting content on or through
                    an online forum, you grant us a worldwide, non-exclusive,
                    royalty-free license (with the right to sublicense) to use,
                    copy, reproduce, process, adapt, modify, publish, transmit,
                    display and distribute your content in any and all media or
                    distribution methods (now known or later developed) for any
                    and all purposes.
                  </p>

                  <p className="mb-4">
                    Any content you post to an online forum may be publicly
                    viewed and used by anyone on the Internet. You are
                    responsible for any content you post, and for any
                    consequences thereof, including the use of your content by
                    other users. To protect your privacy, we remind you and ask
                    you to not submit Personal Data in a public forum.
                  </p>

                  <p className="mb-4">
                    By posting, you agree not to post any content that (i) is
                    unlawful, harmful, threatening, abusive, harassing,
                    tortious, defamatory, vulgar, obscene, libellous, invasive
                    of another individual’s privacy, hateful, or racially,
                    ethnically or otherwise objectionable; (ii) you do not have
                    a right to make available under any law or under contractual
                    or fiduciary relationships; (iii) infringes any patent,
                    trademark, trade secret, copyright or other proprietary
                    rights of any party; or (iv) contains software viruses or
                    any other computer code, files or programs designed to
                    interrupt, destroy or limit the functionality of any
                    computer software or hardware or telecommunications
                    equipment.
                  </p>

                  <p className="mb-4">
                    We may pre-screen, refuse or remove any content that you
                    post. We may also modify or adapt your content in order to
                    transmit, display or distribute it.
                  </p>

                  <p className="mb-4">
                    We do not control the content you or others can publicly
                    post on our Websites and through online forums and as such,
                    we do not guarantee the accuracy, integrity or quality of
                    such content. Under no circumstances will we be liable in
                    any way for any user-contributed content, including, but not
                    limited to, any errors or omissions in any content, or any
                    loss or damage of any kind incurred as a result of the use
                    of any user-contributed content.
                  </p>

                  <p className="mb-4">
                    To request removal of your personal information from our
                    online forums, or to notify us of a posting that violates
                    the rules for posting in BAF’s online forums described
                    above, please contact us using the contact details provided
                    below.
                  </p>

                  <h5 className="mb-4 text-lg font-bold">
                    VI. CONTENT SHARING FEATURES
                  </h5>

                  <p className="mb-4">
                    Our Websites include social media sharing features, such as
                    LinkedIn, Facebook, Instagram, Snapchat, TikTok and Twitter
                    buttons. A Cookie might be set to enable such features to
                    function properly. These features may collect information
                    about your browser and your device, including your IP
                    address as well as which part of our Websites you are
                    visiting.
                  </p>

                  <p className="mb-4">
                    We also have other content sharing features embedded on our
                    Websites, such as YouTube, SoundCloud, Vidyard and Vimeo,
                    which lets us share content with you directly (e.g. videos,
                    podcasts, tracks and playlists). The providers of these
                    content sharing features might collect information about you
                    (including personal information), such as what part of our
                    Website you visited. They do this to be able to recognise
                    you, and in some cases show you personalised content. We are
                    not responsible for the data practices of these providers
                    and recommend you read their own privacy notices.
                  </p>

                  <p className="mb-4">
                    Some features might allow you to email useful information
                    from our Websites to a friend or colleague. If you choose to
                    do so, we may ask you to provide your full name and email
                    address, as well as your friend’s complete email address. We
                    will send your friend a one-time email inviting them to
                    visit our Website, except were prohibited by law.
                  </p>

                  <h5 className="mb-4 text-lg font-bold">
                    VII. SENSITIVE INFORMATION
                  </h5>

                  <p className="mb-4">
                    We ask that you not send or disclose any sensitive personal
                    information (e.g., information related to racial or ethnic
                    origin, political opinions, religion or other beliefs,
                    health, criminal background or trade union membership) to us
                    through our Sites or otherwise.
                  </p>

                  <h5 className="mb-4 text-lg font-bold">
                    VIII. USE OF THE SITES BY CHILDREN
                  </h5>

                  <p className="mb-4">
                    Our Sites are not intended for children under the age of
                    sixteen (16). We ask that children not provide personal
                    information through our Sites. If we become aware that we
                    have collected personal information from a child under the
                    age of 16, we will delete that information from our records.
                  </p>

                  <h5 className="mb-4 text-lg font-bold">
                    IX. PROTECTING YOUR PERSONAL INFORMATION
                  </h5>

                  <p className="mb-4">
                    BAF takes extra steps to ensure that any personal
                    information collected and retained is secured and protected
                    from unauthorized access.
                  </p>

                  <h5 className="mb-4 text-lg font-bold">
                    X. Protecting Personal Information
                  </h5>

                  <p className="mb-4">
                    BAF takes extra steps to ensure that any personal
                    information collected and retained is secured and protected
                    from unauthorized access.
                  </p>

                  <h5 className="mb-4 text-lg font-bold">
                    1. Security and Data Integrity
                  </h5>

                  <p className="mb-4">
                    BAF takes steps to help ensure that the data we possess is
                    housed and transmitted securely. This may include various
                    types of physical and electronic security, including
                    firewall protections, encryption, hashing or truncation of
                    data, and access controls to personal information. While
                    neither we nor any platform can guarantee 100 percent safety
                    from hacks or illegal intrusion, we employ efforts that are
                    designed to ensure that this does not occur.
                  </p>

                  <h5 className="mb-4 text-lg font-bold">2. Retention</h5>

                  <p className="mb-4">
                    BAF retains your information for as long as you have an open
                    and active account profile with us or as needed to provide
                    you the services you requested. We will retain and use your
                    information to comply with our legal obligations, resolve
                    disputes and enforce our agreements.
                  </p>

                  <p className="mb-4">
                    We will retain the minimum amount of personal information
                    necessary to maintain a record of your opt-out if you choose
                    to opt out of our communications
                  </p>

                  <p className="mb-4">
                    Personal information collected on our Websites, through our
                    direct marketing efforts, and through our
                    business-to-business relationships will be retained for up
                    to four (4) years.
                  </p>

                  <h5 className="mb-4 text-lg font-bold">
                    XI. CHANGES TO THIS PRIVACY NOTICE
                  </h5>

                  <p className="mb-4">
                    From time to time, we may update this Privacy Notice. Any
                    changes to it will become effective when it is posted to our
                    website. Please check back to learn of any changes to this
                    Privacy Notice
                  </p>

                  <h5 className="mb-4 text-lg font-bold">
                    XII. STORAGE OF INFORMATION IN THE UNITED STATES
                  </h5>
                  <p className="mb-4">
                    If you are a California resident, the California Consumer
                    Privacy Act of 2018 (“CCPA”) requires certain additional
                    information be provided regarding your rights with respect
                    to your personal information. Please do not rely on the
                    below information and disclosures in this section or
                    otherwise directed to California residents if you are not a
                    resident of California.
                  </p>

                  <h5 className="mb-4 text-lg font-bold">
                    XIII. Notice to California Residents
                  </h5>

                  <p className="mb-4">
                    If you are a California resident, the California Consumer
                    Privacy Act of 2018 (“CCPA”) requires certain additional
                    information be provided regarding your rights with respect
                    to your personal information. Please do not rely on the
                    below information and disclosures in this section or
                    otherwise directed to California residents if you are not a
                    resident of California.
                  </p>

                  <h5 className="mb-4 text-lg font-bold">
                    1. Your California Rights and Choices
                  </h5>

                  <p className="mb-4">
                    California law allows you to make certain requests about
                    your personal information, including pseudonymous
                    information. Specifically, under the CCPA you have the right
                    to <strong>request access</strong> to certain personal
                    information we hold about you, to request that we
                    <strong>delete</strong> that information, and to opt out of
                    the “sale” of your personal information. However, BAF does
                    not “sell” your personal information as defined by the CCPA.
                    To understand the categories of personal information we have
                    collected in the last 12 months, as well as our purposes for
                    such collection, please review the “Information Collection
                    and Use” section above. To understand the categories of
                    parties with whom we have shared the categories of Personal
                    Data within the past 12 months, please see the “Information
                    Sharing by BAF” section above.
                  </p>

                  <p className="mb-4">
                    Please note that certain information may be exempt from such
                    requests under California law. We also will take reasonable
                    steps to verify your identity before responding to a
                    request. Since BAF does not maintain any directly
                    identifiable information about consumers, we will verify you
                    by reading the Cookie ID associated with your browser. You
                    can submit requests to access and delete your personal
                    information by contacting us at
                    <a href="tel:+18324009400" target="_blank" rel="noreferrer">
                      (832-400-9400).
                    </a>
                  </p>

                  <h5 className="mb-4 text-lg font-bold">
                    2. Right to Nondiscrimination
                  </h5>

                  <p className="mb-4">
                    We do not deny, charge different prices for, or provide a
                    different level of quality of goods or services if you
                    choose to exercise these rights.
                  </p>

                  <h5 className="mb-4 text-lg font-bold">
                    3. Authorized Agents
                  </h5>

                  <p className="mb-4">
                    You may also designate an agent to make requests to exercise
                    your rights under CCPA as described above. We will take
                    steps both to verify the identity of the person seeking to
                    exercise their rights as listed above, and to verify that
                    your agent has been authorized to make a request on your
                    behalf through providing us with a signed written
                    authorization or a copy of a power of attorney.
                  </p>

                  <h5 className="mb-4 text-lg font-bold">XIV. Contact Us</h5>

                  <p className="mb-4">
                    For any additional questions, inquiries, or complaints
                    concerning BAF’s privacy notice, please contact us at
                    <a
                      href="mailto:privacy@brandaf.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      privacy@brandaf.com.
                    </a>
                    . If you are looking for our privacy notice as it relates to
                    information collected from our website or our
                    business-to-business contacts, please click here.
                  </p>

                  <p className="mb-4">
                    If you are a visitor from the EU, please visit our dedicated
                    EU Privacy Notice here.{" "}
                  </p>

                  <h5>XV. Definitions</h5>

                  <p className="mb-4">
                    <b>“Cookies” </b>are small text files that are downloaded
                    and stored onto your device (e. g., a computer or
                    smartphone). Cookies allow us to recognize your device and
                    store information about your preferences or past actions. In
                    this Privacy Policy the definition of “Cookies” includes
                    similar technologies that can write or read information on
                    your device such as “Local Shared Objects” (sometimes called
                    Flash Cookies), pixels and web-beacons. There are many
                    misconceptions about cookies. Cookies cannot erase your hard
                    drive, install programs, or collect PII from your browser or
                    device. “Reporting Technologies” means any technology, tool,
                    or code (including cookies, pixels, SDKs, APIs, local shared
                    objects, web-beacons, tags, and scripts) that enables access
                    to or storage of information on a device.
                  </p>

                  <p className="mb-4">
                    <b>“Log files”</b> track actions occurring on Sites and
                    collect data including, as an example, your IP address,
                    browser type, Internet service provider, referring/exit
                    pages, operating system, date/time stamp, click data and
                    types of advertisements viewed.
                  </p>

                  <p className="mb-4">
                    <b>"Pixels or Web beacons"</b> are objects that are embedded
                    in a web page or e-mail and are usually invisible to the
                    user but are used to track a web user’s navigation of a
                    website or collection of websites or pages, interaction with
                    an e-mail, use of mobile applications, the completion of
                    transactions, submittal of applications and browsing
                    behavior.
                  </p>

                  <h4 className="text-xl font-bold">SERVICES PRIVACY NOTICE</h4>

                  <h5 className="mb-4 text-lg font-bold">Introduction</h5>

                  <p className="mb-4">
                    Welcome to the Brand Affiliate Privacy Notice "(
                    <b>Privacy Notice</b>”). Brand Affiliate LLC (“{" "}
                    <b> Brand Affiliate,” “BAF,” “we,” “us,” or “our</b>”) is an
                    affiliate marketing company. We’ve created this resource to
                    provide you with valuable transparency and choice on how we
                    might collect, use, and share your personal information as a
                    consumer (or visitor) to the website(s) of one of our
                    affiliate creators or advertisers.
                  </p>

                  <h5 className="mb-4 text-lg font-bold">
                    Scope of this Privacy Notice
                  </h5>

                  <p className="mb-4">
                    To make some of our services possible and to make smarter
                    decisions, we need to process consumer personal information.
                    This Privacy Policy is intended to describe how your
                    personal information as a consumer is collected and used by
                    BAF while providing our affiliate marketing services. We
                    believe that your privacy and data protection is essential
                    to the growth and prosperity of the internet, and that a
                    great online experience can provide significant benefits to
                    consumers when done properly. In accordance with these
                    beliefs, BAF strives to create results for creators,
                    advertisers and consumers in revolutionary ways without
                    compromising privacy.
                  </p>

                  <p className="mb-4">
                    This Privacy Notice is created and maintained as part of our
                    commitment to provide you with transparency and choice on
                    how we process their personal information. We have taken
                    steps to describe our personal information processing
                    activities as clearly and easy-to-read as possible and
                    encourage you to read the entire Privacy Notice. If you
                    still have questions about our privacy practices, you can
                    always contact us at{" "}
                    <a
                      href="mailto:privacy@brandaf.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      privacy@brandaf.com
                    </a>
                  </p>

                  <p className="mb-4">
                    If you are looking for information on how BAF collects and
                    uses personal information in connection with visitors to our
                    website (brandaf.com), which includes our clients,
                    prospective clients, business-to-business contacts, please
                    click here to view our Website Privacy Notice.
                  </p>

                  <h5 className="mb-4 text-lg font-bold">
                    I. Overview of BAF and Affiliate Marketing
                  </h5>

                  <p className="mb-4">
                    When you visit websites and mobile applications, there are
                    almost always third parties working behind the scenes to
                    help provide a great digital experience. BAF is one of these
                    companies and we offer affiliate marketing technology and
                    services that keeps your favorite blogs free, your favorite
                    stores in business, and your experience with brands more
                    relevant.
                  </p>

                  <h5 className="mb-4 text-lg font-bold">1. What do we do?</h5>
                  <p className="mb-4">
                    Affiliate marketing is the process of a brand (for example,
                    a retailer) paying a commission to a creator (for example a
                    blog or a news site) for the transactions the creator helps
                    to drive by promoting the brand’s products or services.
                    Creators will promote products or services from advertisers
                    to visitors of their properties (examples include websites
                    or mobile applications) and earn a piece of the profit for
                    each transaction generated. BAF operates an affiliate
                    network, which helps creators and advertisers partner with
                    each other. An affiliate network is the platform used by
                    creators and advertisers to discover and form new
                    partnerships, define criteria for qualified marketing
                    efforts and is used to ensure that appropriate parties are
                    rewarded for qualified activities.
                  </p>

                  <h5 className="mb-4 text-lg font-bold">
                    2. Privacy by Design
                  </h5>
                  <p className="mb-4">
                    We have a deep-rooted foundation and culture of privacy and
                    data protection at BAF. We integrate privacy in all aspects
                    of our business, including strategy, product development,
                    delivery, and more. We set a ‘privacy-first’ tone early and
                    often, proactively working to limit our data collection to
                    what is necessary to provide our services.
                  </p>

                  <p className="mb-4">
                    In that spirit, BAF does not collect, store, process, or
                    allow in our systems any of your personal information that
                    is linked, or is intended to be linked, to an identified
                    individual, such as names, addresses, phone numbers, email
                    addresses, driver’s license numbers, or financial account
                    numbers, which we define as directly identifiable
                    information (“DII”). We have built, and continue to build,
                    all our products and services without the use of any DII.
                  </p>

                  <p className="mb-4">
                    As part of our Privacy by Design philosophy, we consider and
                    prioritize data protection and privacy throughout the entire
                    lifecycle of the data from before it is collected to when it
                    is ultimately destroyed. We constantly strive to maintain
                    unparalleled visibility with consumers. We provide
                    transparency and choice through our website and encourage
                    the same practice throughout the internet.
                  </p>

                  <h5 className="mb-4 text-lg font-bold">
                    II. Information Collection & Use
                  </h5>

                  <p className="mb-4">
                    Unless voluntarily provided by you, BAF does not collect or
                    use any DII. Instead, BAF only processes <b>pseudonymous</b>{" "}
                    personal information. Pseudonymous or pseudonymized personal
                    information is information that does not directly identify a
                    specific consumer without the use of additional information.
                    BAF only collects and processes personal information in a
                    pseudonymous format and has both technical and
                    organizational measures in place to make sure that
                    additional information is not used to identify or link that
                    pseudonymous information to an identified consumer.Some
                    examples of pseudonymous information that we process include
                    IP addresses, Cookie IDs, or referring site website
                    addresses, order IDs, and browsing activity. We do not link
                    or attribute pseudonymous information to DII, such as your
                    name, email address, or phone number.
                  </p>

                  <p className="mb-4">
                    Using pseudonymous information allows us to see and
                    understand your journey between creators and advertisers
                    without revealing to us your identity. In other words, we do
                    not directly identify you using the pseudonymous information
                    that we process. In fact, we have no interest in knowing
                    your DII details, and we work to proactively keep such
                    information out of our systems. We will only ever collect
                    DII if it is expressly provided to us, such as when you
                    contact us on your own initiative via our contact form or
                    otherwise. Even in these instances, we will not tie this DII
                    to pseudonymous information about you that we may have, such
                    as your site browsing or transaction data.{" "}
                  </p>

                  <h5 className="mb-4 text-lg font-bold">
                    1. Pseudonymous Information Collected by BAF
                  </h5>
                  <p className="mb-4">
                    The majority of the data that BAF processes is machine
                    generated and is collected when a consumer visits and
                    interacts with one of our advertisers or creators, such as a
                    website or mobile application that leverages our services.
                    Such information includes the following:
                  </p>

                  <ol>
                    <li>
                      {" "}
                      <b>Commercial or transactions information:</b> E.g.,
                      information relating to online purchases and actions made
                      by you, order IDs, order timestamps, and other purchasing
                      information of products and services.
                    </li>

                    <li>
                      {" "}
                      <b>Identifiers and Device Information:</b> E.g., hashed
                      emails, mobile identifiers (such as IDFA or AAID), Cookie
                      IDs, user agent strings which provides browser type and
                      version, and IP address.
                    </li>

                    <li>
                      {" "}
                      <b>Online browsing activity information:</b> E.g.,
                      browsing activity and information on your interaction with
                      a website, application, or advertisement where our
                      technology is deployed, such as clicks, ad impressions,
                      time stamps, referring site URLs, and current site URLs.
                    </li>

                    <li>
                      {" "}
                      <b>Location data:</b> E.g., location information based on
                      IP addresses.
                    </li>
                  </ol>

                  <h5 className="mb-4 text-lg font-bold">
                    2. How BAF Collects Pseudonymous Information
                  </h5>
                  <h5 className="mb-4 text-lg font-bold">
                    Information Collected Online:
                  </h5>

                  <p className="mb-4">
                    We collect the types of pseudonymous information identified
                    above in a variety of ways. However, we principally use
                    Reporting Technologies, such as cookies, pixels, tags, or
                    other similar technologies to collect the pseudonymous
                    information we need to provide our services. We leverage
                    these technologies to collect the necessary pseudonymous
                    information from you directly as you navigate or interact
                    with our advertisers and creators.
                  </p>

                  <h5 className="mb-4 text-lg font-bold">
                    Information Received from Clients:
                  </h5>

                  <p className="mb-4">
                    Our clients may also provide us with information relating to
                    their customers, prospective customers, or other
                    individuals, which we handle on the behalf of our clients as
                    a service provider. Our clients are independently
                    responsible to determine how they engage with their
                    customers and determine the handling of their customer data.
                    Under these circumstances, the use of such personal
                    information is subject to the privacy policies of the
                    client’s privacy policies. We are not responsible for the
                    privacy practices of our clients, and accordingly, this
                    Privacy Policy and the rights acknowledged herein do not
                    apply to the data that BAF handles on behalf its clients.
                  </p>

                  <h5 className="mb-4 text-lg font-bold">
                    3. BAF’s Purposes for Collecting Pseudonymous Information
                  </h5>

                  <p className="mb-4">
                    BAF collects pseudonymized personal information for a
                    variety of purposes, as detailed below.
                  </p>

                  <p className="mb-4">
                    <strong>Attribution:</strong> BAF primarily uses
                    pseudonymous information to attribute a referral or
                    marketing effort by a creator to an event or transaction
                    with an advertiser. When a consumer responds to or views an
                    affiliate advertisement served by one of our creators, we
                    may assign a unique identifier that is tied to that
                    consumer’s internet browser or device, often via a cookie or
                    similar technology. One function of these technologies is to
                    provide BAF with the information needed to facilitate the
                    verification and payment of affiliate commissions to a
                    creator when a consumer clicks on a link, creates a lead, or
                    makes a purchase directly on an advertiser’s website. Even
                    if the consumer re-visits the advertiser's site directly,
                    BAF is able to recognize the unique identifier assigned
                    earlier, and is able to continue to attribute the consumer’s
                    activities on the advertiser's site and credit the creator
                    that referred the consumer where appropriate. In other
                    words, if a consumer reads a blog about a pair of shoes and
                    decides to visit that advertiser’s website and purchase that
                    pair of shoes, we would ensure that the blog gets
                    compensated for referring the consumer to that advertiser’s
                    website.
                  </p>

                  <p className="mb-4">
                    <strong>Cross-Device Reporting:</strong> We may also collect
                    pseudonymous information across multiple browsers or devices
                    to help us more accurately credit a creator for their
                    referral or marketing effort. Our cross-device reporting
                    allows us to follow your journey from a creator to a
                    potential transaction even if you decide to switch browsers
                    or devices throughout that journey. For example, if you
                    interact with a blog post on your phone referring them to
                    purchase a certain pair of shoes, but later decide to switch
                    to a desktop computer to make the purchase, we would be able
                    to leverage our cross-device reporting technologies to
                    ensure that the blog site is credited for the referral.
                  </p>

                  <p className="mb-4">
                    <strong>Insights and Analytics:</strong> BAF also uses the
                    pseudonymous information collected to provide our creators
                    and advertisers with reports to help with their marketing
                    efforts. This information is generally used by our clients
                    to enhance their ad campaigns, such as by learning where the
                    optimal ad placement is on their website, or learning what
                    type of products or services consumers are more interested
                    in. We will generally aggregate or de-identify any
                    pseudonymous information prior to compiling these reports so
                    that it is no longer technically feasible for the data to be
                    linked to you, your device, or your household (“aggregated
                    information”). For example, to measure the effectiveness of
                    an ad or ad campaign, we may compile a report indicating how
                    many unique individuals clicked on an ad. The report, in
                    this example, would only consist of the ad itself and the
                    total number of unique clicks, with no means for the
                    recipient of the report to relate the clicks back to any
                    particular individual. While many of these reports are at
                    the aggregate level, we also produce reports that contain
                    customer-level pseudonymous information. These reports can
                    help our clients identify areas of growth, such as
                    identifying new partnerships or customer audiences.
                  </p>

                  <p className="mb-4">
                    <strong>Internal Business Purposes:</strong> BAF may also
                    collect and process personal information for some of our
                    internal business purposes, such as:
                  </p>

                  <ul>
                    <li>
                      Auditing transaction, such as, counting ad impressions,
                      verifying positioning and quality of ad impression.
                    </li>
                    <li>
                      Auditing our systems and services for compliance with
                      applicable regulations and standards.
                    </li>
                    <li>
                      Detecting security incidents, protecting against
                      malicious, deceptive, fraudulent, or illegal activity, and
                      prosecuting those responsible for that activity
                    </li>
                    <li>
                      Debugging to identify and repair errors that impair
                      existing intended functionality.
                    </li>
                    <li>
                      Short-term, transient use, including, but not limited to,
                      the contextual customization of ads shown as part of a
                      single interaction.
                    </li>
                    <li>
                      Performing services on behalf of our advertisers or
                      creators, such as maintaining or servicing accounts,
                      providing customer service.
                    </li>
                    <li>
                      Undertaking internal research for technological
                      development and demonstration.
                    </li>
                    <li>
                      Undertaking activities to verify or maintain the quality
                      or safety of our systems and services, and to improve,
                      upgrade, or enhance our systems and services.
                    </li>
                    <li>
                      Other purposes as required or permitted by applicable law,
                      or with your consent.
                    </li>
                  </ul>

                  <h5 className="mb-4 text-lg font-bold">
                    III. Information Sharing by BAF
                  </h5>

                  <p className="mb-4">
                    We only share your information for our limited business
                    purposes, as described in this Privacy Notice, including:
                  </p>

                  <p className="mb-4">
                    <strong>Sharing with BAF’s Partners and Affiliates:</strong>{" "}
                    We may share your personal information with companies
                    affiliated with BAF under Publicis Groupe for the purposes
                    described in this privacy policy.
                  </p>

                  <p className="mb-4">
                    <strong>Sharing with Service Providers:</strong> We may
                    share your personal information with service providers to
                    assist or facilitate in the services we provide to our
                    clients, such as vendors, administrative service providers,
                    technology providers, and carefully selected partners for
                    services including, but not limited to, data validation,
                    enhancement, information verification and suppression
                    services.
                  </p>

                  <p className="mb-4">
                    <strong>Sharing with Third Parties:</strong> We may share
                    your personal information and/or aggregated information with
                    third parties including advertisers, promotional partners,
                    sponsors, and creators, for the purposes stated above in
                    this Privacy Notice, including for attribution, cross-device
                    reporting, and insight and analytics purposes.
                  </p>

                  <p className="mb-4">
                    <strong>Sharing for Legal Purposes:</strong> In addition, we
                    may share personal information with third parties in order
                    to: (a) comply with legal process or a regulatory
                    investigation (e. g. a subpoena or court order); (b) enforce
                    our Terms of Service, this Privacy Notice, or other
                    contracts with you, including investigation of potential
                    violations thereof; and/or (c) respond to claims that any
                    content violates the rights of third parties.
                  </p>

                  <p className="mb-4">
                    <strong>Sharing for Fraud Prevention & Safety:</strong> We
                    may share your personal information with third parties or
                    service providers to protect the rights, property or
                    personal safety of us, our platform, our customers, our
                    agents and affiliates, its users and/or the public. We
                    likewise may provide information to other companies and
                    organizations (including law enforcement) for fraud
                    protection, and spam/malware prevention, and similar
                    purposes.
                  </p>

                  <p className="mb-4">
                    <strong>
                      Sharing in the Event of a Corporate Transaction:
                    </strong>{" "}
                    We may also share personal information in the event of a
                    major corporate transaction, including for example a merger,
                    investment, acquisition, reorganization, consolidation,
                    bankruptcy, liquidation, or sale of some or all of our
                    assets, or for purposes of due diligence connected with any
                    such transaction.
                  </p>

                  <p className="mb-4">
                    <strong>Sharing with your Consent:</strong> We may also
                    share your personal information for any other purpose with
                    your consent.
                  </p>

                  <h4 className="mb-4 text-lg font-bold">
                    {" "}
                    Modifications to this Privacy Notice
                  </h4>

                  <p className="mb-4">
                    From time to time, we may update this Privacy Notice. Any
                    changes to it will become effective when it is posted to our
                    website. You can determine when this Notice was last revised
                    by checking the “Last Modified" legend at the top of this
                    Policy. Please check back periodically to learn of any
                    changes to this Privacy Notice.
                  </p>

                  <p className="mb-2">
                    © BrandAffiliate LLC. All rights reserved.
                  </p>
                  <p className="mt-0">Updated on March 10, 2022.</p>
                </div>
                <footer className="flex w-full my-10 min-h-[100px] items-center justify-between rounded-b">
                  <button
                    className="background-transparent mr-1 mb-1 py-2  text-sm font-bold uppercase text-red-500 outline-none focus:outline-none"
                    type="button"
                    onClick={() => toggleModal()}
                  >
                    <HollowButton title="Cancel" color="bafBlack" small />
                  </button>
                  <button
                    className="background-transparent mr-1 mb-1 py-2  text-sm font-bold uppercase text-red-500 outline-none focus:outline-none"
                    type="button"
                    onClick={() => check()}
                  >
                    <HollowButton
                      title="Agree"
                      color="bafWhite"
                      background="bafBlack"
                      small
                      backgroundHover
                    />
                  </button>
                </footer>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default PrivacyAgreementModal;
