import React, { useState } from "react";
import HollowButton from "../Shared/HollowButton";
import { ReactComponent as CloseIcon } from "../images/close-button2.svg";

const SoftwareCreatorsPolicyModal = ({
  showModal,
  handleModal,
  showCheck,
  handleCheck,
}) => {
  const toggleModal = () => {
    handleModal();
  };
  const check = () => {
    handleModal();
    handleCheck();
  };
  return (
    <>
      {showModal ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center  bg-bafGrey bg-opacity-30 outline-none focus:outline-none backdrop-brightness-[0.2] backdrop-blur-[2px]">
            <div className="relative my-6 mx-auto h-[70%] w-[90%] max-w-[750px] overflow-y-hidden rounded-2xl bg-bafBlack sm:min-w-[350px]">
              <div className=" relative flex w-full flex-col rounded-lg border-0 shadow-lg outline-none focus:outline-none">
                <header className="flex  flex-col justify-center p-5">
                  <h3 className="text-center text-lg font-semibold text-bafWhite sm:text-3xl">
                    Brandaf Creators Policy
                  </h3>

                  <button
                    className="absolute right-5"
                    onClick={() => toggleModal()}
                  >
                    <CloseIcon className="w-6 h-6" />
                  </button>
                </header>
              </div>
              <div className="modal-bg flex h-full flex-wrap overflow-y-scroll p-8">
                <p>
                  Brand Affiliate Network Quality has a policy for
                  software-based creators (“Software Creators”) to promote
                  complete transparency to BAF and its clients, and to ensure
                  that creators are clear on what is permissible within the
                  Brand Affiliate network. The “Spirit of the Law” and “Rules”
                  sections below provides a better understanding. Brand
                  Affiliate allows Software Creators into the BAF Network
                  provided they perform in accordance with the terms of the BAF
                  Creator Service Agreement.
                </p>
                <h3 className="mt-4 mb-4">Spirit of the Policy</h3>
                <p>
                  At Brand Affiliate, our goal is to provide{" "}
                  <b>transparency and choice</b> to all of our clients. We
                  strive to be as open and transparent as possible with regards
                  to software operation, distribution, performance in specific
                  traffic situations, and impacts on advertisers’ businesses and
                  consumers’ behaviors. This transparency allows advertisers and
                  creators to make good choices for mutually beneficial
                  affiliations, ultimately creating better experiences for
                  consumers.
                </p>
                <p className="mb-4 mt-4">
                  <u>Consumers</u>
                </p>
                <p>
                  Consumers have the right to understand and acknowledge any
                  Brand Affiliate creator software installed onto their device
                  or browser. Further, the benefits to the consumer of any such
                  installation must be obvious to the consumer, to Brand
                  Affiliate, and to the advertiser.
                </p>
                <p className="mb-4 mt-4">
                  <u>Advertisers</u>
                </p>
                <p>
                  Advertisers must have knowledge of any software’s function and
                  intent prior to distribution or delivery of traffic. The
                  advertiser has the right to expect that their brand will be
                  appropriately displayed and protected at all times.
                </p>
                <p className="mb-4 mt-4">
                  <u>Creators</u>
                </p>
                <p>
                  Creators must endeavor to provide transparency, drive traffic,
                  and earn commissions within the rules of their advertisers’
                  program terms, and within reasonable consumer expectations.
                  They will act responsibly and responsively in all situations.
                </p>
                <p className="mb-4 mt-4">
                  <u>Brand Affiliate</u>
                </p>
                <p>
                  Brand Affiliate is responsible for reviewing and approving all
                  software active in the network. It provides the platform for
                  transparency and choice by which all relationships are
                  governed.
                </p>
                <h3 className="mb-4 mt-4">
                  Rules for Software Creators in the Brand Affiliate Network
                </h3>
                <p>
                  The following rules clarify the requirements for Software
                  Creators to operate in the Brand Affiliate Network.
                  Software-based activity includes, but is not limited to
                  downloadable software, browser plugins, and browser
                  extensions. Pursuant to the BAF Creator Service Agreement, any
                  unapproved activity may result in account termination,
                  suspension/deactivation, and/or reversal of commissions.
                </p>
                <p className="mb-4 mt-4">
                  1. All Software Creator tools, and any versions of those tools
                  intended for distribution for Brand Affiliate advertisers,
                  must be tested and approved by Brand Affiliate prior to
                  distribution. It is the Software Creator’s responsibility to
                  have any changes or modifications re-tested and approved by
                  Brand Affiliate. Feature enhancements and usability changes
                  are subject to Network Quality approval and must be added and
                  disclosed in the Software Creator’s website description(s).
                </p>
                <p className="mb-4 mt-4">
                  2. Software Creators must provide full transparency to Brand
                  Affiliate and its advertisers regarding distribution and
                  functionality. Software Creators may not bundle their browser
                  extensions for distribution.
                </p>
                <p className="mb-4 mt-4">
                  3. Software Creators may only redirect a consumer through a
                  Brand Affiliate link after a consumer has clicked on the
                  downloadable software, browser plugin, or browser extension.
                  Automatic redirect functionality is strictly prohibited.
                </p>
                <p className="mb-4 mt-4">
                  4. Prior to installation on end users’ devices from any
                  download location, Software Creators must receive opt-in
                  acceptance by end users of an end user license agreement
                  (“EULA”) and its privacy policy that clearly and accurately
                  describes the behavior and conduct of the software, including,
                  but not limited to, an explicit description of what activities
                  will trigger the placement of affiliates’ cookies.
                </p>
                <p className="mb-4 mt-4">
                  5. Software uninstallation must always be available, easy, and
                  complete from consumers’ browsers and/or operating systems.
                </p>
                <p className="mb-4 mt-4">
                  6. Software Creators must recognize and respect any
                  creator-driven traffic immediately prior to engaging the
                  consumer. Specifically, all software must recognize Brand
                  Affiliate domains and afsrc=1 codes in Brand Affiliate links.
                  When a Brand Affiliate domain or the afsrc=1 code is detected,
                  the software must not operate or redirect the consumer to the
                  advertiser site using the Software Creator’s PID.
                </p>
                <br />
                <div className="flex-col">
                  <p className="mb-4 mt-4">
                    © BrandAffiliate LLC. All rights reserved.
                  </p>
                  <p className=" mt-4"> Updated on Feb. 23, 2022.</p>
                </div>

                <footer className="flex items-center my-10 min-h-[100px] w-full justify-between rounded-b">
                  <button
                    className="background-transparent mr-1 mb-1 py-2  text-sm font-bold uppercase text-red-500 outline-none focus:outline-none"
                    type="button"
                    onClick={() => toggleModal()}
                  >
                    <HollowButton title="Cancel" color="bafBlack" small />
                  </button>
                  <button
                    className="background-transparent mr-1 mb-1 py-2 text-sm font-bold uppercase text-red-500 outline-none focus:outline-none"
                    type="button"
                    onClick={() => check()}
                  >
                    <HollowButton
                      title="Agree"
                      color="bafWhite"
                      background="bafBlack"
                      small
                      backgroundHover
                    />
                  </button>
                </footer>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default SoftwareCreatorsPolicyModal;
