import React from "react";
import Select, { components } from "react-select";
import { ReactComponent as OpenedArrow } from "../images/dropdown-arrow-opened.svg";
import { ReactComponent as ClosedArrow } from "../images/dropdown-arrow-closed.svg";

const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (provided, state) => ({
    ...provided,
    boxShadow:
      "inset -4px -4px 4px #FFFFFF, inset 4px 4px 4px rgba(0, 0, 0, 0.05)",
    // border: "2px solid black",
    borderRadius: state.isFocused ? "10px 10px 0px 0px" : "10px",
    padding: "0.2rem 0.2rem",
    fontWeight: "",
    fontSize: "14px",
    border: "none",
  }),
  menu: (provided, state) => ({
    ...provided,
    borderRadius: "0px 0px 10px 10px",
    marginTop: 0,
    boxShadow:
      "inset 4px 4px 4px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
  }),
  menuList: (provided, state) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    "& :last-child": {
      borderRadius: "0px 0px 10px 10px",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    paddingTop: "15px",
    paddingBottom: "15px",
    fontSize: "14px",
    backgroundColor:
      (state.isFocused && state.isSelected) || state.isSelected
        ? "#A590E3"
        : state.isFocused && !state.isSelected
        ? "#F2EAFF"
        : "",
    ":first-of-type": {
      boxShadow:
        (state.isFocused || state.isSelected) &&
        "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
    ":last-of-type": {
      boxShadow:
        (state.isFocused || state.isSelected) &&
        "inset 4px 4px 4px rgba(0, 0, 0, 0.05)",
    },
    boxShadow:
      (state.isFocused || state.isSelected) &&
      "inset 4px 4px 4px rgba(0, 0, 0, 0.05)",
  }),
};

export default function CustomSelect({
  error,
  label,
  onChange,
  options,
  value,
  onBlur,
  className,
  bold,
  labelClassName,
  smallText,
}) {
  const defaultValue = (options, value) => {
    return options
      ? options.find((option) => {
          return option.value === value;
        })
      : "";
  };

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          {props.selectProps.menuIsOpen ? <OpenedArrow /> : <ClosedArrow />}
        </components.DropdownIndicator>
      )
    );
  };

  return (
    <div className={`${className} my-4`}>
      <label
        className={`${bold && "font-bold"} ${
          smallText ? "text-xs" : "text-sm"
        } ml-2 ${labelClassName}`}
      >
        {label}
      </label>
      <Select
        defaultValue={defaultValue(options, value)}
        value={defaultValue(options, value)}
        onBlur={onBlur}
        onChange={(value) => onChange(value)}
        options={options}
        components={{ DropdownIndicator }}
        styles={customStyles}
        theme={(theme) => ({
          ...theme,
          borderRadius: 10,
          colors: {
            ...theme.colors,
            primary25: "white",
            primary: "#A18FC3",
          },
        })}
      />

      <p className="ErrorMessage ml-1 mt-2 text-[10px] text-errorRed">
        {error}
      </p>
    </div>
  );
}
