import React from "react";

export default function MiniAvatar({ src, className }) {
  return (
    <img
      src={src}
      alt="avatar"
      className={`h-[25px] w-[25px] rounded-full border border-coolGrey ${className}`}
    />
  );
}
